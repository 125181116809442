import NoInternetPlaceholder from 'components/ErrorPlaceholders/NoInternetPlaceholder';
import React, { useEffect, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

const NoInternetWrapper: React.FC<Props> = ({ children }: Props) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    setIsOnline(navigator.onLine);
  }, []);

  window.addEventListener('online', () => {
    setIsOnline(true);
  });

  window.addEventListener('offline', () => {
    setIsOnline(false);
  });

  if (isOnline) {
    return <>{children}</>;
  }

  return <NoInternetPlaceholder />;
};

export default NoInternetWrapper;
