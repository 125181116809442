const CA_RESOURCE = {
  translation: {
    loading: 'Carregant',
    search: {
      cancel: 'Cancel·lar',
      noResults:
        'No hi ha resultats de cerca, però paga la pena donar un cop d’ull als resultats de baix',
    },
    menu: {
      switchProfiles: 'Canvia el perfil',
      settings: 'Configuració',
      close: 'Tancar',
      logOut: 'Tancar sessió',
    },
    button: {
      apply: 'Aplicar',
      continue: 'Continua',
      cancel: 'Cancel·lar',
      confirm: 'Confirmar',
      play: 'Play',
      addToWatchList: 'Afegeix a la llista de seguiment',
      removeFromWatchList: 'Elimina de la llista de seguiment',
      moreInfo: 'Més informació',
      resume: 'Restablir',
      close: 'Tancar',
    },
    login: {
      header: 'Inici de sessió al compte',
      title: 'Benvingut a',
      subTitle:
        'Aprofiteu al màxim les vostres subscripcions en streaming amb Disney, Amazon i molt més.',
      text: 'Tot el que vulguieu veure en un sol lloc.',
      button: 'Iniciar Sessió',
      placeholder: {
        username: "Nom d''usuari",
        password: 'Contrasenya',
      },
      errors: {
        email: {
          noValid: 'El correu electrònic ha de ser un correu electrònic vàlid',
          required: 'El correu electrònic és un camp obligatori',
        },
        password: {
          required: 'La contrasenya és un camp obligatori',
        },
        wrongPass: {
          first: 'Vaja, aquesta no és la contrasenya correcta. Torneu-ho a provar o feu clic al',
          second: '"Has oblidat la contrasenya"',
          third: 'botó de sota.',
        },
      },
    },
    forgotPassword: {
      message:
        'No us preocupeu. Restablir la contrasenya és fàcil. Només cal que introduïu la vostra adreça de correu electrònic a continuació i feu clic a "ENVIAR"',
      button: 'ENVIAR',
      placeholder: {
        username: "Nom d''usuari",
      },
      errors: {
        email: {
          required: 'El correu electrònic és un camp obligatori',
        },
        emailNotRegister: 'Adreça electrònica no registrada!',
        emailSent: 'Correu electrònic de restabliment de la contrasenya enviat!',
      },
    },
    profilesPage: {
      title: 'Qui mira?',
      subTitle:
        'Tothom al teu cotxe pot tenir una experiència de TV personalitzada. Afegeix un nou perfil.',
      newProfile: 'Nou perfil',
    },
    newProfile: {
      isKid: 'Infants?',
      loading: 'Carregant',
      profileCreated: 'Perfil creat correctament',
      profileSelection: 'Selecció de perfils',
    },
    settings: {
      accountDetails: {
        primary: 'Detalls del compte',
        secondary: 'Editeu el nom i el correu electrònic del titular del compte.',
      },
      manageProfiles: {
        primary: 'Gestionar perfils',
        secondary: 'Editar els detalls',
      },
      parentControl: {
        primary: 'Control dels pares',
        secondary: 'Crea PIN / Canvia PIN',
        instruction: '(Introduïu 4 dígits mentre el teclat està obert)',
        recoverPin: 'Recuperar PIN',
        pinSentTo: 'PIN enviat a',
      },
      membershipStatus: {
        primary: 'Estat de membre',
        secondary: 'Actiu',
      },
      deactivateAccount: {
        primary: 'Desactivar compte',
        secondary: 'Tanqueu el vostre compte i desenllace els vostres dispositius.',
        enterPass: 'Introduïu la contrasenya',
      },
      privacyPolicy: {
        text: 'Política de privacitat',
      },
      activeLanguage: 'Idioma actiu',
      defaultLanguage: 'Idioma del sistema',
      settings: 'Configuració',
      lang: {
        en: 'Anglès',
        it: 'Italià',
        de: 'Alemany',
        esar: 'Espanyol (Argentina)',
        fr: 'Francès',
        ja: 'Japonès',
        ro: 'Romanès',
        da: 'Danès',
        nl: 'Holandès',
        ca: 'Català',
        lb: 'Luxemburguès',
        no: 'Noruec',
        ptbr: 'Portuguès (BR)',
        zhcn: 'Xinès',
        ms: 'Malaia',
        ta: 'Tàmil',
        bs: 'Bosnià',
        bg: 'Búlgar',
        hr: 'Croata',
        tr: 'Turc',
        cs: 'Txec',
        et: 'Estonià',
        fi: 'Finlandès',
        el: 'Grec',
        hu: 'Hongarès',
        ga: 'Irlandès',
        is: 'Islandès',
        lv: 'Letó',
        lt: 'Lituà',
        mt: 'Maltès',
        pl: 'Polonès ',
        pt: 'Portuguès (PT)',
        ru: 'Rus',
        sr: 'Sèrbi',
        sk: 'Eslovac',
        sl: 'Eslovè',
        sv: 'Suec',
        mi: 'Maorí',
        ar: 'Àrab',
        af: 'Africans',
        zhtw: 'Xinès (Tradicional)',
        es: 'Espanyol (Espanya)',
        ko: 'Coreà',
        enus: 'Anglès (Estats Units)',
        deat: 'Alemany (Àustria)',
        dech: 'Alemany (Suïssa)',
        deli: 'Alemany (Liechtenstein)',
        dede: 'Alemany (Alemanya)',
        enca: 'Anglès (Canadà)',
        engb: 'Anglès',
        enie: 'Anglès (Irlanda)',
        ennz: 'Anglès (Nova Zelanda)',
        enza: 'Anglès (Sud-àfrica)',
        esmx: 'Espanyol (Mèxic)',
        frca: 'Francès (Canadà)',
        frch: 'Francès (Suïssa)',
        itch: 'Italià (Suïssa)',
        jajp: 'Japonès (Japó)',
        kokr: 'Coreà (Corea del Sud)',
        nb: 'Noruec Bokmål',
        nn: 'Noruec Nynorsk',
        zhch: 'Xinès (RPC)',
      },
      language: {
        primary: 'Idioma',
      },
    },
    show: {
      more: ' Mostra més',
      less: ' Mostra menys',
    },
    showPage: {
      seasons: 'Temporades',
      cast: 'Repartiment',
      producers: 'Productors',
      genre: 'Gènere',
      directedBy: 'Dirigit per',
      season: 'Temporada',
    },
    accountDetails: {
      firstName: 'Nom',
      lastName: 'Cognom',
      email: 'Correu electrònic',
      password: 'Contrasenya',
      changePassword: 'Canvia la contrasenya',
      forgotPassword: 'Has oblidat la contrasenya',
    },
    childPinPopup: {
      title: 'Protecció infantil',
      subTitle: 'Crear (actualitzar) el PIN per a la protecció i fantil',
      pinUpdated: 'El PIN s’ha actualitzat correctament',
      pinIsIncorrect: 'El PIN és incorrecte',
    },
    deactivateAccount: {
      title: 'Esteu segur que voleu desactivar el vostre compte?',
      description:
        'Un cop ho feu, se us tancarà la sessió de tots els serveis i ja no podreu veure el contingut al vostre cotxe',
      deactivateButton: 'Desactivar compte',
    },
    servicesPage: {
      add: 'Afegeix als serveis',
      remove: 'Eliminar dels serveis',
      overflow: {
        title: 'Paquets seleccionats',
        subtitle: 'Seleccioneu els vostres serveis',
        description: 'Afegiu tots els serveis que utilitzeu a la vostra guia.',
      },
    },
    manageProfile: {
      text: 'Gestionar perfils',
    },
    watchlist: {
      title:
        'Aquí teniu totes les pel·lícules i programes que heu afegit a la vostra llista de seguiment',
      noTitles: 'Actualment no teniu cap títol a la vostra llista de seguiment',
      addText:
        'Per afegir-ne un, feu clic a + Afegeix a la llista de seguiment en qualsevol programa al qual vulgueu tornar i veure en una data posterior.',
      subtext: {
        start: 'Per afegir, feu clic a ',
        toWatchList: 'Afegeix a la llista de seguiment',
        end: 'qualsevol programa al qual us agradaria tornar i veure en una data posterior.',
      },
    },
    streamingService: {
      title: 'Trieu Serveis de streaming en temps real',
      subTitle: 'Afegiu els vostres serveis seleccionats perquè apareguin a la vostra Guia de TV',
      streamingServices: 'Serveis de streaming',
    },
    profileUpdate: {
      backToSettings: 'Torna a Configuració',
      updatedSuccess: "L''usuari s''ha actualitzat correctament",
      apply: 'Aplicar',
      wrongPassword: 'Contrasenya incorrecta',
    },
    changePasswordPage: {
      backToSettings: 'Torna a Configuració',
      currentPass: 'Contrasenya actual',
      newPass: 'Nova contrasenya',
      confirmPass: 'Confirma la contrasenya',
      change: 'Canviar',
      changeUserInfo: "Canviar la informació de l''usuari",
      passwordDoNotMatch: 'Les contrasenyes no coincideixen o no són vàlides',
      passwordUpdated: "La contrasenya s''ha actualitzat correctament",
    },
    noServices: {
      title: 'Actualment no teniu cap servei a la vostra Guia de TV',
      text: 'Per aprofitar al màxim ScreenHits TV cal que afegiu serveis. Feu clic aquí. Per a afegir els streamers sense cost addicional. Si actualment no esteu subscrit a cap servei d’streaming, afegiu el nostre paquet GRATUÏT aquí i comenceu a descobrir contingut fantàstic de BBC, ITV, MY5, Channel4, UKTV I STV.',
      button: 'Afegeix serveis',
    },
    deleteProfile: {
      cantDeleteProfile: 'No podeu eliminar el vostre perfil actual',
      areYouSure: 'Confirmeu que voleu suprimir aquest perfil?',
      delete: 'Suprimeix',
    },
    notFoundPage: {
      title: 'Alguna cosa ha fallat',
      text: 'No et preocupis, però, tot està bé. Tornarem amb aquesta pàgina.',
      home: 'Inici',
    },
    noInternet: {
      title: 'Sense connexió a Internet',
      description: 'No us preocupeu, tot està bé. Tornarem amb aquesta pàgina.',
      button: 'Recarrega',
    },
  },
};

export default CA_RESOURCE;
