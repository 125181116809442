import { UserData } from 'types/Auth';
import { Backgrounds } from 'types/Backgrounds';
import { ChannelMediaData } from 'types/Channels';
import { GenreMediaData } from 'types/Genres';
import { MediaData, WatchList } from 'types/Media';
import { OtherGenreFilterEnum } from 'types/OtherGenre';
import { ProfileData } from 'types/Profile';
import { AppActions, AppActionsEnums } from './types';

const setUserData = (data: UserData): AppActions => ({
  type: AppActionsEnums.SET_USER_DATA,
  payload: data,
});

const setIsAuth = (isAuth: boolean): AppActions => ({
  type: AppActionsEnums.SET_IS_AUTH,
  payload: isAuth,
});

const setChildProtection = (isProtected: boolean): AppActions => ({
  type: AppActionsEnums.SET_CHILD_PROTECTION,
  payload: isProtected,
});

const setWatchList = (watchList: WatchList | []): AppActions => ({
  type: AppActionsEnums.SET_WATCH_LIST,
  payload: watchList,
});

const setGenreParams = (params: { key: string; value: string | number }): AppActions => ({
  type: AppActionsEnums.SET_GENRE_PARAMS,
  payload: params,
});

const setGenreMedia = (payload: Array<GenreMediaData>): AppActions => ({
  type: AppActionsEnums.SET_GENRE_MEDIA,
  payload,
});

const setSelectedMedia = (payload: MediaData | ChannelMediaData): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_MEDIA,
  payload,
});

const setSelectedProfile = (payload: ProfileData): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_PROFILE,
  payload,
});

const setRedirectUrl = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_REDIRECT_URL,
  payload,
});

const setBackgrounds = (payload: Backgrounds): AppActions => ({
  type: AppActionsEnums.SET_BACKGROUNDS,
  payload,
});

const setRedirectedFrom = (payload: string | undefined): AppActions => ({
  type: AppActionsEnums.SET_REDIRECTED_FROM,
  payload,
});

const setPrevUrl = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_PREV_URL,
  payload,
});

const SetOtherGenreFilter = (payload: OtherGenreFilterEnum): AppActions => {
  return {
    type: AppActionsEnums.SET_OTHER_GENRE_FILTER,
    payload,
  };
};

const SetSelectedGenreId = (payload: number): AppActions => ({
  type: AppActionsEnums.SET_SELECTED_GENRE_ID,
  payload,
});

const SetLocation = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_LOCATION,
  payload,
});

const SetSearchQuery = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_SEARCH_QUERY,
  payload,
});

const SetChannelId = (payload: string): AppActions => ({
  type: AppActionsEnums.SET_CHANNEL_ID,
  payload,
});

export {
  setUserData,
  setIsAuth,
  setChildProtection,
  setWatchList,
  setGenreParams,
  setGenreMedia,
  setSelectedMedia,
  setSelectedProfile,
  setRedirectUrl,
  setBackgrounds,
  setRedirectedFrom,
  setPrevUrl,
  SetOtherGenreFilter,
  SetSelectedGenreId,
  SetLocation,
  SetSearchQuery,
  SetChannelId,
};
