export default {
  primary: '#faae1c',
  secondary: '#000000',
  white: '#ffffff',
  black: '#000000',
  gray: '#8d8d8d',
  navBarLink: '#a2a2a2',
  lightGray: '#ebebeb;',
  darkGray: '#707070',
  btnLinkLight: '#c9c9c9',
  nextSlideBG: 'linear-gradient(to left, #000, rgba(84, 84, 84, 0))',
  prevSlideBG: 'linear-gradient(to right, #000, rgba(84, 84, 84, 0))',
  cardContentBG: 'linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100% )',
  settingsBorderColor: '#707070',
  listSecondary: '#7f7f7f',
  cardOverlay: 'linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))',
  backgroundOverlay: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 100%)',
  darkInput: '#323232',
  dialogBackgroundOpacity: 'rgba(0, 0, 0, 0.7)',
};
