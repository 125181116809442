import { Container, Typography, Button } from '@mui/material';
import NoMatchesIcons from 'components/icons/NoMatchesIcons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { appLinks } from 'routes/routes';

const SimpleErrorPlaceholder: React.FC = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <Container
        maxWidth={'xs'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <NoMatchesIcons sx={{ fontSize: { xs: 150, md: 200 }, mb: { xs: 4, md: 6 } }} />

        <Typography variant='h1' textAlign='center'>
          Something went wrong
        </Typography>
        <Typography variant='body1' textAlign='center'>
          Don’t worry though, everything is ok. We will come back with this page.
        </Typography>
        <Button
          component={NavLink}
          to={appLinks.discover.link}
          variant='outlined'
          size='large'
          sx={{ mt: 2 }}
        >
          Home
        </Button>
      </Container>
    </Container>
  );
};

export default SimpleErrorPlaceholder;
