const IT_CH_RESOURCE = {
  translation: {
    loading: 'In caricamento',
    search: {
      cancel: 'Cancella',
      noResults: 'Nessun risultato trovato ma i titoli selezionati meritano un approfondimento',
    },
    menu: {
      switchProfiles: 'Cambia profilo',
      settings: 'Impostazioni',
      close: 'Chiudi',
      logOut: 'Disconnettersi',
    },
    button: {
      apply: 'Seleziona',
      continue: 'Continua',
      cancel: 'Cancella',
      confirm: 'Conferma',
      play: 'Riproduci',
      addToWatchList: 'Aggiungi alla Mia Lista',
      removeFromWatchList: 'Rimuovi dalla Mia Lista',
      moreInfo: "Piu'' informazioni",
      resume: 'Riprendi',
      close: 'Cancella',
    },
    login: {
      header: 'Account Login',
      title: 'Benvenuto in',
      subTitle:
        'Ottieni il massimo dai tuoi abbonamenti streaming con Disney+, Amazon Prime e molti altri.',
      text: 'Tutto ciò che desideri guardare in un unico posto.',
      button: 'Accedi',
      placeholder: {
        username: 'Nome utente',
        password: 'Password',
      },
      errors: {
        email: {
          noValid: 'Inserisci un indirizzo e-mail valido',
          required: 'È necessario inserire un indirizzo e-mail',
        },
        password: {
          required: 'È necessario inserire una password',
        },
        wrongPass: {
          first:
            'Ops, password errata. Riprova o clicca il pulsante sottostante “Password dimenticata”. ',
          second: '“Password dimenticata” ',
          third: ' ',
        },
      },
    },
    forgotPassword: {
      message:
        'Non preoccuparti. Reimpostare la password è facile. Inserisci il tuo indirizzo e-mail qui sotto e clicca “INVIA”',
      button: 'INVIA',
      placeholder: {
        username: 'Nome utente',
      },
      errors: {
        email: {
          required: 'È necessario inserire un indirizzo e-mail',
        },
        emailNotRegister: 'Indrizzo e-mail non registrato!',
        emailSent: 'E-mail per reimpostare la password inviata!',
      },
    },
    profilesPage: {
      title: 'Chi sta Guardando?',
      subTitle:
        "Tutti in auto possono vivere un'esperienza televisiva personalizzata. Aggiungi un nuovo profilo.",
      newProfile: 'Nuovo Profilo',
    },
    newProfile: {
      isKid: 'Bambini?',
      loading: 'In caricamento',
      profileCreated: 'Profilo creato correttamente!',
      profileSelection: 'Seleziona Profilo',
    },
    settings: {
      accountDetails: {
        primary: 'Dettagli account',
        secondary: 'Modifica il nome e l’e-mail del titolare dell’account.',
      },
      manageProfiles: {
        primary: 'Gestici profili',
        secondary: 'Gestisci i dettagli del tuo account',
      },
      parentControl: {
        primary: 'Proteggi la visione dei minori',
        secondary: 'Crea PIN/Cambia PIN',
        instruction: '(Inserisci 4 cifre mentre la tastiera è aperta)',
        recoverPin: 'Recupera PIN',
        pinSentTo: 'PIN inviato a',
      },
      membershipStatus: {
        primary: 'Stato dell’abbonamento',
        secondary: 'Attiva',
      },
      deactivateAccount: {
        primary: 'Disattiva Account',
        secondary: 'Chiudi il tuo account e scollega i tuoi dispositivi',
        enterPass: 'Inserisci la password',
      },
      activeLanguage: 'Lingua Selezionata',
      defaultLanguage: 'Lingua di sistema',
      privacyPolicy: {
        text: 'Informazioni sulla Privacy',
      },
      settings: 'Impostazioni',
      lang: {
        en: 'Inglese',
        it: 'Italiano',
        de: 'Tedesco',
        esar: 'Spagnolo(Argentina)',
        fr: 'Francese',
        ja: 'Giapponese',
        ro: 'Rumeno',
        da: 'Danese',
        nl: 'Tedesco',
        ca: 'Catalano',
        lb: 'Lussemburghese',
        no: 'Norvegese',
        ptbr: 'Portoghese(Brasile)',
        zhcn: 'Cinese',
        ms: 'Malese',
        ta: 'Tamil',
        bs: 'Bosniaco',
        bg: 'Bulgaro',
        hr: 'Croato',
        tr: 'Turco',
        cs: 'Ceco',
        et: 'Estone',
        fi: 'Finlandese',
        el: 'Greco',
        hu: 'Ungherese',
        ga: 'Irlandese',
        is: 'Islandese',
        lv: 'Lettone',
        lt: 'Lituano',
        mt: 'Maltese',
        pl: 'Polacco',
        pt: 'Portoghese(Portogallo)',
        ru: 'Russo',
        sr: 'Serbo',
        sk: 'Slovacco',
        sl: 'Sloveno',
        sv: 'Svedese',
        mi: 'Maori',
        ar: 'Arabo',
        af: 'Afrikaans',
        zhtw: 'Cinese(Tradizionale)',
        es: 'Spagnolo (Spagna)',
        ko: 'Coreano',
        enus: 'Inglese (Stati Uniti)',
        deat: 'Tedesco (Austria)',
        dech: 'Tedesco (Svizzera)',
        deli: 'Tedesco (Liechtenstein)',
        dede: 'Tedesco (Germania)',
        enca: 'Inglese (Canada)',
        engb: 'Inglese',
        enie: 'Inglese (Irlanda)',
        ennz: 'Inglese (Nuova Zelanda)',
        enza: 'Inglese (Sudafrica)',
        esmx: 'Spagnolo (Messico)',
        frca: 'Francese (Canada)',
        frch: 'Francese (Svizzera)',
        itch: 'Italiano (Svizzera)',
        jajp: 'Giapponese (Giappone)',
        kokr: 'Coreano (Corea del Sud)',
        nb: 'Norvegese (Bokmål)',
        nn: 'Norvegese (Nynorsk)',
        zhch: 'Cinese (RPC)',
      },
      language: {
        primary: 'Lingua',
      },
    },
    show: {
      more: " Mostra di piu''",
      less: ' Mostra meno',
    },
    showPage: {
      seasons: 'Stagioni',
      cast: 'Cast',
      producers: 'Produttori',
      genre: 'Genere',
      directedBy: 'Regia',
      season: 'Stagione',
    },
    accountDetails: {
      firstName: 'Nome',
      lastName: 'Cognome',
      email: 'E-mail',
      password: 'Password',
      changePassword: 'Cambia Password',
      forgotPassword: 'Password dimenticata',
    },
    childPinPopup: {
      title: 'Protezione minori',
      subTitle: 'Crea (aggiorna) il PIN per la proteggere la visione dei minori',
      pinUpdated: 'PIN aggiornato con successo',
      pinIsIncorrect: 'Il PIN non è corretto',
    },
    deactivateAccount: {
      title: 'Sei sicuro di voler disattivare il tuo account?',
      description:
        'Una volta fatto, sarete disconnessi da tutti i servizi e non potrete più visualizzare i contenuti.',
      deactivateButton: 'Disattiva account ',
    },
    servicesPage: {
      add: 'Aggiungi servizi ',
      remove: 'Rimuovi servizi ',
      overflow: {
        title: 'Pacchetti selezionati',
        subtitle: 'Seleziona i tuoi servizi',
        description: 'Aggiungi alla tua Guida TV i servizi che usi. ',
      },
    },
    manageProfile: {
      text: 'Gestici profili',
    },
    watchlist: {
      title: 'Ecco tutti i film e gli shows che hai aggiunto alla Mia Lista',
      noTitles: 'La Mia Lista al momento non contiene titoli',
      addText:
        'Per aggiungere, clicca + Aggiungi alla Mia Lista in qualsiasi show tu voglia rivisitare per rivederlo più tardi.',
      subtext: {
        start: 'Per aggiungere, clicca ',
        toWatchList: '+ Aggiungi alla Mia Lista',
        end: 'in qualsiasi show tu voglia rivisitare per rivederlo più tardi.',
      },
    },
    streamingService: {
      title: 'Scegli i servizi streaming',
      subTitle: 'Aggiungi i servizi che hai selezionato per includerli nella tua Guida TV ',
      streamingServices: 'Servizi Streaming',
    },
    profileUpdate: {
      backToSettings: 'Torna alle Impostazioni',
      updatedSuccess: 'Utente aggiornato correttamente',
      apply: 'Seleziona',
      wrongPassword: 'Password errata',
    },
    changePasswordPage: {
      backToSettings: 'Torna alle Impostazioni',
      currentPass: 'Password attuale',
      newPass: 'Nuova password',
      confirmPass: 'Conferma la password',
      change: 'Cambia',
      changeUserInfo: 'Modifica le informazioni dell’utente',
      passwordDoNotMatch: 'Le password non corrispondono o non sono valide',
      passwordUpdated: 'Password aggiornata con successo',
    },
    noServices: {
      title: 'Al momento non hai selezionato nessun servizio per la tua Guida TV.',
      text: 'Aggiungi i tuoi servizi streaming preferiti per godere al meglio dell’esperienza di ScreenHits TV. Fai click qui per aggiungere gli streamer ai cui sei abbonato, non ci sono costi aggiuntivi.  Se al momento non sei abbonato ad alcun servizio streaming, aggiungi la nostra offerta bundle GRATUITA e scopri i contenuti straordinari di BBC, ITV, My5, Channel 4, UKTV e STV. ',
      button: 'Aggiungi servizi',
    },
    deleteProfile: {
      cantDeleteProfile: 'Non puoi cancellare il tuo attuale profilo',
      areYouSure: 'Sei sicuro di voler cancellare questo profilo?',
      delete: 'Cancella',
    },
    notFoundPage: {
      title: 'Qualcosa è andato storto.',
      text: 'Stiamo cercando di risolvere il problema, al momento non e’ possibile accedere alla pagina.',
      home: 'Home',
    },
    noInternet: {
      title: 'Nessuna connessione Internet',
      description: 'Non preoccuparti, tutto è a posto. Torneremo con questa pagina.',
      button: 'Ricarica',
    },
  },
};

export default IT_CH_RESOURCE;
