const KO_RESOURCE = {
  translation: {
    loading: '로드 중',
    search: {
      cancel: '취소',
      noResults: '검색 결과가 없으나 아래 검색 결과를 확인해 볼 수 있습니다.',
    },
    menu: {
      switchProfiles: '프로필 전환',
      settings: '설정',
      close: '닫기',
      logOut: '로그아웃',
    },
    button: {
      apply: '승인',
      continue: '계속',
      cancel: '취소',
      confirm: '확인',
      play: '재생',
      addToWatchList: '관심목록에 추가',
      removeFromWatchList: '관심목록에서 삭제',
      moreInfo: '자세한 정보',
      resume: '다시 시작',
      close: '닫기',
    },
    login: {
      header: '계정 로그인',
      title: '환영합니다',
      subTitle: 'Disney, Amazon 등의 스트리밍 구독을 최대한 활용하세요.',
      text: '보고 싶은 모든 콘텐츠를 한 곳에서.',
      button: '로그인',
      placeholder: {
        username: '사용자 이름',
        password: '비밀번호',
      },
      errors: {
        email: {
          noValid: '이메일은 유효한 이메일이어야 합니다',
          required: '이메일은 필수 입력란입니다.',
        },
        password: {
          required: '비밀번호는 필수 입력란입니다',
        },
        wrongPass: {
          first: '죄송합니다, 올바른 비밀번호가 아니 었습니다. 다시 시도하거나 클릭하십시오',
          second: '“비밀번호를 잊어버렸습니다” ',
          third: '아래 버튼을 누릅니다.',
        },
      },
    },
    forgotPassword: {
      message:
        '괜찮아요. 비밀번호 재설정은 쉽습니다. 아래에 이메일 주소를 입력하고 "보내기"를 클릭하십시오.',
      button: '보내기',
      placeholder: {
        username: '사용자 이름',
      },
      errors: {
        email: {
          required: '이메일은 필수 입력란입니다',
        },
        emailNotRegister: '등록되지 않은 이메일 주소입니다!',
        emailSent: '비밀번호 재설정 메일이 전송되었습니다!',
      },
    },
    profilesPage: {
      title: '어떤 프로필로 시청 하시겠습니까?',
      subTitle: '당신의 차 안 모두가 개인 맞춤형 TV 경험을 할 수 있습니다. 새로운 프로필을 추가하세요.',
      newProfile: '새 프로필',
    },
    newProfile: {
      isKid: '키즈',
      loading: '로드 중',
      profileCreated: '프로필이 성공적으로 생성되었습니다!',
      profileSelection: '프로필 선택',
    },
    settings: {
      accountDetails: {
        primary: '계정 상세 정보',
        secondary: '계정 소유자 이름 및 이메일을 수정합니다.',
      },
      manageProfiles: {
        primary: '프로필 관리',
        secondary: '상세 정보 수정',
      },
      parentControl: {
        primary: '보호자 통제',
        secondary: 'PIN 생성 / PIN 변경',
        instruction: '(열린 키보드에서 4자리를 입력해주세요)',
        recoverPin: 'PIN 복구',
        pinSentTo: 'PIN 전송 대상',
      },
      membershipStatus: {
        primary: '멤버십 상세 정보',
        secondary: '활성 사용자',
      },
      deactivateAccount: {
        primary: '계정 비활성화',
        secondary: '계정을 해지하고 디바이스 연결을 해제하세요.',
        enterPass: '비밀번호를 입력하세요',
      },
      activeLanguage: '기본 언어',
      defaultLanguage: '시스템 언어',
      privacyPolicy: {
        text: '개인정보 처리방침',
      },
      settings: '설정',
      lang: {
        en: '영어',
        it: '이탈리아어',
        de: '독일어',
        esar: '스페인어(아르헨티나)',
        fr: '프랑스어',
        ja: '일본어',
        ro: '루마니아어',
        da: '덴마크어',
        nl: '네덜란드어',
        ca: '카탈로니아어',
        lb: '룩셈부르크어',
        no: '노르웨이어',
        ptbr: '포르투갈어(브라질)',
        zhcn: '중국어',
        ms: '말레이어',
        ta: '타밀어',
        bs: '보스니아어',
        bg: '불가리아어',
        hr: '크로아티아어',
        tr: '터키어',
        cs: '체코어',
        et: '에스토니아어',
        fi: '핀란드어',
        el: '그리스어',
        hu: '헝가리어',
        ga: '아일랜드어',
        is: '아이슬란드어',
        lv: '라트비아어',
        lt: '리투아니아어',
        mt: '몰타어',
        pl: '폴란드어',
        pt: '포르투갈어(포르투갈)',
        ru: '러시아어',
        sr: '세르비아어',
        sk: '슬로바키아어',
        sl: '슬로베니아어',
        sv: '스웨덴어',
        mi: '마오리어',
        ar: '아랍어',
        af: '아프리칸스어',
        zhtw: '중국어(번체)',
        es: '스페인어(스페인)',
        ko: '한국어',
        enus: '영어 (미국)',
        deat: '독일어 (오스트리아)',
        dech: '독일어 (스위스)',
        deli: '독일어 (리히텐슈타인)',
        dede: '독일어 (독일)',
        enca: '영어 (캐나다)',
        engb: '영어',
        enie: '영어 (아일랜드)',
        ennz: '영어 (뉴질랜드)',
        enza: '영어 (남아프리카)',
        esmx: '스페인어 (멕시코)',
        frca: '프랑스어 (캐나다)',
        frch: '프랑스어 (스위스)',
        itch: '이탈리아어 (스위스)',
        jajp: '일본어 (일본)',
        kokr: '한국어 (대한민국)',
        nb: '노르웨이어 (복말)',
        nn: '노르웨이어 (뉘노르스크)',
        zhch: '중국어 (중화인민공화국)',
      },
      language: {
        primary: '언어',
      },
    },
    show: {
      more: '자세히 보기',
      less: '  간단히 보기',
    },
    showPage: {
      seasons: '시즌별',
      cast: '출연',
      producers: '프로듀서',
      genre: '장르',
      directedBy: '감독',
      season: '시즌',
    },
    accountDetails: {
      firstName: '이름',
      lastName: '성',
      email: '이메일',
      password: '비밀번호',
      changePassword: '비밀번호 변경',
      forgotPassword: '비밀번호를 잊으셨나요',
    },
    childPinPopup: {
      title: '자녀 보호',
      subTitle: '자녀 보호를 위한 PIN 생성(업데이트)',
      pinUpdated: 'PIN이 성공적으로 업데이트되었습니다.',
      pinIsIncorrect: 'PIN이 잘못되었습니다.',
    },
    deactivateAccount: {
      title: '계정을 비활성화하시겠습니까?',
      description:
        '계정을 비활성화하면 모든 서비스에서 로그아웃되며 더 이상 차량에서 콘텐츠를 볼 수 없습니다.',
      deactivateButton: '계정 비활성화',
    },
    servicesPage: {
      add: '서비스에 추가',
      remove: '서비스에서 삭제',
      overflow: {
        title: '선택한 팩',
        subtitle: '서비스 선택',
        description: '사용하는 각 서비스를 가이드에 추가하세요.',
      },
    },
    manageProfile: {
      text: '프로필 관리',
    },
    watchlist: {
      title: '다음은 관심목록에 추가한 모든 영화 및 콘텐츠입니다.',
      noTitles: '현재 관심목록에 제목이 없습니다.',
      addText: '추가하려면 나중에 다시 보고 싶은 콘텐츠에서 + 관심목록에 추가를 클릭하십시오.',
      subtext: {
        start: '추가하려면 다음을 클릭하십시오',
        toWatchList: '관심 목록에 추가',
        end: '나중에 다시 와서 보고 싶은 쇼를 위해.',
      },
    },
    streamingService: {
      title: '스트리밍 서비스 선택',
      subTitle: '선택한 서비스를 추가하여 TV 가이드에 표시',
      streamingServices: '스트리밍 서비스',
    },
    profileUpdate: {
      backToSettings: '설정으로 돌아가기',
      updatedSuccess: '사용자가 성공적으로 업데이트되었습니다',
      apply: '승인',
      wrongPassword: '잘못된 비밀번호',
    },
    changePasswordPage: {
      backToSettings: '설정으로 돌아가기',
      currentPass: '현재 비밀번호',
      newPass: '새 비밀번호',
      confirmPass: '비밀번호 확인',
      change: '변경',
      changeUserInfo: '사용자 정보 변경',
      passwordDoNotMatch: '비밀번호가 일치하지 않거나 유효하지 않습니다',
      passwordUpdated: '비밀번호가 성공적으로 업데이트되었습니다',
    },
    noServices: {
      title: '현재 TV 가이드에 서비스가 없습니다.',
      text: 'ScreenHits TV를 최대한 활용하려면 서비스를 추가해야 합니다. 추가 비용 없이 이미 구독 중인 스트리머를 추가하려면 여기를 클릭하세요. 현재 스트리밍 서비스를 구독하고 있지 않다면 여기에 무료 번들을 추가하고 BBC, ITV, My5, Channel 4, UKTV 및 STV에서 훌륭한 콘텐츠를 시청하세요.',
      button: '서비스 추가',
    },
    deleteProfile: {
      cantDeleteProfile: '현재 프로필을 삭제할 수 없습니다.',
      areYouSure: '이 프로필을 삭제하시겠습니까?',
      delete: '삭제',
    },
    notFoundPage: {
      title: '문제가 발생했습니다.',
      text: '그래도 걱정하지 마세요. 모든 것이 정상입니다. 이 페이지로 다시 찾아오겠습니다.',
      home: '홈',
    },
    noInternet: {
      title: '인터넷 연결 없음',
      description: '걱정하지 마세요. 모든 것이 괜찮습니다. 이 페이지로 돌아올 것입니다.',
      button: '새로고침',
    },
  },
};
export default KO_RESOURCE;
