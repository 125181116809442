import Cookies from 'js-cookie';

export const COOKIES = {
  ACCESS_TOKEN: 'screen_hits_access_token',
  USER_TOKEN: 'screen_hits_user_token',
};

const setTokenToCookies = (token: string): string | undefined =>
  Cookies.set(COOKIES.ACCESS_TOKEN, token, { expires: 365 });

const getTokenFromCookies = (): string | undefined => Cookies.get(COOKIES.ACCESS_TOKEN);

const removeTokenFromCookies = (): void => Cookies.remove(COOKIES.ACCESS_TOKEN);

const setUserTokenToCookies = (token: string): string | undefined => {
  return Cookies.set(COOKIES.USER_TOKEN, token);
};

const getUserTokenFromCookies = (): string | undefined => Cookies.get(COOKIES.USER_TOKEN);

const removeUserTokenFromCookies = (): void => Cookies.remove(COOKIES.USER_TOKEN);

export {
  setTokenToCookies,
  getTokenFromCookies,
  removeTokenFromCookies,
  setUserTokenToCookies,
  getUserTokenFromCookies,
  removeUserTokenFromCookies,
};
