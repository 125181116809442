import { Cache, SWRConfig } from 'swr';

interface Props {
  children: React.ReactNode;
}

function sessionStorageProvider() {
  const map = new Map<string, unknown>(JSON.parse(sessionStorage.getItem('app-cache') || '[]'));

  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    sessionStorage.setItem('app-cache', appCache);
  });

  // eslint-disable-next-line
  return map as unknown as Cache<any>;
}

const SWRConfigWrapper: React.FC<Props> = ({ children }: Props) => {
  return (
    <SWRConfig
      value={{
        provider: sessionStorageProvider,
        suspense: true,
        revalidateOnFocus: false,
        revalidateOnMount: true,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default SWRConfigWrapper;
