import { useEffect } from 'react';
import { Box } from '@mui/material';

import { Outlet } from 'react-router-dom';

import { useAppContext } from 'store';
import useSWR from 'swr';
import { getWatchList, watchListApiUrl } from 'api/watchListApi';
import { setWatchList } from 'store/actions';
import { WatchList } from 'types/Media';

const ProfileLayout: React.FC = (): JSX.Element => {
  const { data: watchListData } = useSWR<WatchList>(watchListApiUrl, getWatchList);

  const { dispatch } = useAppContext();

  useEffect(() => {
    watchListData && dispatch(setWatchList(watchListData));
  }, [dispatch, watchListData]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Outlet />
    </Box>
  );
};

export default ProfileLayout;
