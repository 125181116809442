import { Backdrop } from '@mui/material';
import ProgressLoader from '../ProgressLoader';

const BackdropLoader: React.FC = (): JSX.Element => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.tooltip + 1,
        backgroundColor: 'secondary.main',
      }}
      open={true}
    >
      <ProgressLoader />
    </Backdrop>
  );
};

export default BackdropLoader;
