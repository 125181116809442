import { MenuItems, MenuItemsPromise } from 'types/MenuItems';
import { apiClient } from './apiClient';
import ENDPOINTS from './endpoints';

const endpoint = ENDPOINTS.MISC;

const menuApiUrl = {
  getItems: `${endpoint}/menuitems`,
};

const getMenuItems = (): MenuItemsPromise =>
  apiClient.get<MenuItems>(menuApiUrl.getItems).then((res) => res.data);

export { menuApiUrl, getMenuItems };
