import { lazy, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { appLinks } from './routes';
import ReactGA from 'react-ga';
import RequireAuth from './RequireAuth';
import RequireUserData from './RequireUserData';
import ProfileLayout from 'app/ProfileLayout';

const Registration = lazy(() => import('containers/Auth/Registration'));

const Login = lazy(() => import('containers/Auth/Login'));
const LoginWithForm = lazy(() => import('containers/Auth/Login/WithForm'));
const LoginWithQRDetails = lazy(() => import('containers/Auth/Login/WithQRDetails'));
const LoginWithQRDetailsCinemo = lazy(
  () => import('../containers/Auth/Login/WithQRDetails/QrWithCinemo'),
);
const BrowseProfiles = lazy(() => import('containers/Auth/Profiles'));
const ForgotPassword = lazy(() => import('containers/Auth/ForgotPassword'));
const CreateNewProfileWrapper = lazy(() => import('containers/Auth/Profiles/CreateProfileWrapper'));

const Layout = lazy(() => import('app/Layout'));
const Discover = lazy(() => import('containers/Pages/Discover'));
const WatchList = lazy(() => import('containers/Pages/WatchList'));
const TVGuide = lazy(() => import('containers/Pages/TVGuide'));
const LiveTV = lazy(() => import('containers/Pages/LiveTV'));
const Genres = lazy(() => import('containers/Pages/Genres'));
const ShowPage = lazy(() => import('containers/Pages/ShowPage'));
const Settings = lazy(() => import('containers/Pages/Settings'));
const Search = lazy(() => import('containers/Pages/Search'));
const Player = lazy(() => import('../containers/Pages/Player'));
const StreamingServicesPage = lazy(() => import('containers/Pages/StreamingServices'));
const ManageProfiles = lazy(() => import('../containers/Pages/ManageProfiles'));
const ManageProfilesUpdate = lazy(() => import('../containers/Pages/UpdateProfile'));
const TvFriends = lazy(() => import('../containers/Pages/TvFriends'));
const AccountUpdate = lazy(() => import('../containers/Pages/AccountUpdate'));
const ChangePassword = lazy(() => import('../containers/Pages/ChangePassword'));
const OtherGenres = lazy(() => import('../containers/Pages/OtherGenres'));

const NoMatches = lazy(() => import('containers/Pages/NoMatches'));

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string;
ReactGA.initialize(TRACKING_ID);

const AppRoutes: React.FC = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const appRoutes = useRoutes([
    {
      path: appLinks.home.link,
      element: <Layout />,
      children: [
        {
          path: appLinks.login.link,
          element: <Login />,
          children: [
            {
              path: appLinks.loginWithQR.link,
              element: <LoginWithQRDetails />,
            },
            {
              path: appLinks.loginWithQRCinemo.link,
              element: <LoginWithQRDetailsCinemo />,
            },
            {
              index: true,
              element: <LoginWithForm />,
            },
          ],
        },
        {
          path: appLinks.forgotPassword.link,
          element: <ForgotPassword />,
        },
        {
          path: appLinks.profiles.link,
          element: (
            <RequireUserData>
              <BrowseProfiles />
            </RequireUserData>
          ),
        },
        {
          path: appLinks.createNewProfile.link,
          element: (
            <RequireUserData>
              <CreateNewProfileWrapper />
            </RequireUserData>
          ),
        },
        {
          path: appLinks.profilesManage.link,
          element: (
            <RequireUserData>
              <ManageProfiles />
            </RequireUserData>
          ),
        },
        {
          path: appLinks.profilesManageUpdate.link,
          element: (
            <RequireUserData>
              <ManageProfilesUpdate />
            </RequireUserData>
          ),
        },
        {
          path: appLinks.profile.link,
          element: (
            <RequireAuth>
              <ProfileLayout />
            </RequireAuth>
          ),
          children: [
            {
              path: appLinks.discover.link,
              element: <Discover />,
            },
            {
              path: appLinks.tvGuide.link,
              element: <TVGuide />,
            },
            {
              path: appLinks.player.link,
              element: <Player />,
            },
            {
              path: appLinks.watchlist.link,
              element: <WatchList />,
            },
            {
              path: appLinks.liveTV.link,
              element: <LiveTV />,
            },
            {
              path: appLinks.genres.link,
              element: <Genres />,
            },
            {
              path: appLinks.search.link,
              element: <Search />,
            },
            {
              path: appLinks.settings.link,
              element: <Settings />,
            },
            {
              path: appLinks.singleMedia.link,
              element: <ShowPage />,
            },
            {
              path: appLinks.streamingServices.link,
              element: <StreamingServicesPage />,
            },
            {
              path: appLinks.tvFriends.link,
              element: <TvFriends />,
            },
            {
              path: appLinks.accountUpdate.link,
              element: <AccountUpdate />,
            },
            {
              path: appLinks.changePassword.link,
              element: <ChangePassword />,
            },
            {
              path: appLinks.otherGenres.link,
              element: <OtherGenres />,
            },
          ],
        },
        {
          path: appLinks.register.link,
          element: <Registration />,
        },
        {
          index: true,
          element: <Login />,
        },
        {
          path: '*',
          element: <NoMatches />,
        },
      ],
    },
  ]);
  return appRoutes;
};

export default AppRoutes;
