import { AppStateContext, AppActions, AppActionsEnums } from './types';

const reducer = (state: AppStateContext, action: AppActions): AppStateContext => {
  switch (action.type) {
    case AppActionsEnums.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case AppActionsEnums.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case AppActionsEnums.SET_CHILD_PROTECTION:
      return {
        ...state,
        childProtection: action.payload,
      };
    case AppActionsEnums.SET_WATCH_LIST:
      return {
        ...state,
        watchList: action.payload,
      };
    case AppActionsEnums.SET_GENRE_PARAMS:
      return {
        ...state,
        genreParams: {
          ...state.genreParams,
          [action.payload.key]: action.payload.value,
        },
      };
    case AppActionsEnums.SET_GENRE_MEDIA:
      return {
        ...state,
        genreMedia: action.payload,
      };
    case AppActionsEnums.SET_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: action.payload,
      };
    case AppActionsEnums.SET_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case AppActionsEnums.SET_REDIRECT_URL:
      return {
        ...state,
        redirectURL: action.payload,
      };
    case AppActionsEnums.SET_BACKGROUNDS:
      return {
        ...state,
        backgrounds: action.payload,
      };
    case AppActionsEnums.SET_REDIRECTED_FROM:
      return {
        ...state,
        redirectedFrom: action.payload,
      };
    case AppActionsEnums.SET_PREV_URL:
      return {
        ...state,
        prevUrl: action.payload,
      };
    case AppActionsEnums.SET_OTHER_GENRE_FILTER:
      return {
        ...state,
        otherGenreFilter: action.payload,
      };
    case AppActionsEnums.SET_SELECTED_GENRE_ID:
      return {
        ...state,
        selectedGenreId: action.payload,
      };
    case AppActionsEnums.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case AppActionsEnums.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case AppActionsEnums.SET_CHANNEL_ID:
      return {
        ...state,
        channelId: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
