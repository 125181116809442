const DE_AT_RESOURCE = {
  translation: {
    loading: 'Lädt',
    search: {
      cancel: 'Abbrechen',
      noResults:
        'Keine Suchergebnisse gefunden, aber die untenstehenden Vorschläge sind definitiv einen Blick wert.',
    },
    menu: {
      switchProfiles: 'Profil wechseln',
      settings: 'Einstellungen',
      close: 'Schließen',
      logOut: 'Abmelden',
    },
    button: {
      apply: 'Anwenden',
      continue: 'Weiter',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      play: 'Ansehen',
      addToWatchList: 'Zu meiner Liste hinzufügen',
      removeFromWatchList: 'Von Liste entfernen',
      moreInfo: 'Mehr Infos',
      resume: 'Fortsetzen',
      close: 'Schließen',
    },
    login: {
      header: 'Konto-Login',
      title: 'Willkommen bei',
      subTitle:
        'Holen Sie das Beste aus Ihren Streaming-Abonnements mit Disney, Amazon und mehr heraus.',
      text: 'Alles, was Sie sich ansehen wollen, an einem Ort.',
      button: 'Einloggen',
      placeholder: {
        username: 'E-Mail-Adresse',
        password: 'Passwort',
      },
      errors: {
        email: {
          noValid: 'E-Mail muss eine gültige E-Mail sein',
          required: 'E-Mail ist ein Pflichtfeld',
        },
        password: {
          required: 'Passwort ist ein Pflichtfeld',
        },
        wrongPass: {
          first:
            'Ups, das war nicht das richtige Passwort. Bitte versuche Sie es nochmal oder klicken Sie auf ',
          second: '“Passwort vergessen” ',
          third: ' ',
        },
      },
    },
    forgotPassword: {
      message:
        'Machen Sie sich keine Sorgen. Das Zurücksetzen Ihres Passworts ist ganz einfach. Geben Sie einfach Ihre E-Mail-Adresse ein und klicken Sie auf SENDEN.',
      button: 'SENDEN',
      placeholder: {
        username: 'E-Mail-Adresse',
      },
      errors: {
        email: {
          required: 'E-Mail ist ein Pflichtfeld',
        },
        emailNotRegister: 'E-Mail Adresse nicht registriert!',
        emailSent: 'E-Mail zum Zurücksetzen des Passwortes gesendet!',
      },
    },
    profilesPage: {
      title: 'Wer schaut?',
      subTitle:
        'Jeder in Ihrem Auto kann eine personalisierte TV-Erfahrung haben. Fügen Sie ein neues Profil hinzu.',
      newProfile: 'Neues Profil',
    },
    newProfile: {
      isKid: 'Kind?',
      loading: 'Lädt',
      profileCreated: 'Profil erfolgreich erstellt!',
      profileSelection: 'Profilauswahl',
    },
    settings: {
      accountDetails: {
        primary: 'Details zum Konto',
        secondary: 'Name des Kontoinhabers und E-Mail bearbeiten.',
      },
      manageProfiles: {
        primary: 'Profile verwalten',
        secondary: 'Details bearbeiten',
      },
      parentControl: {
        primary: 'Kindersicherung',
        secondary: 'PIN erstellen / PIN ändern',
        instruction: '(Bitte geben Sie vier Ziffern mit geöffneter Tastatur ein.)',
        recoverPin: 'PIN wiederherstellen',
        pinSentTo: 'PIN gesendet an',
      },
      membershipStatus: {
        primary: 'Status der Mitgliedschaft',
        secondary: 'Aktiv',
      },
      deactivateAccount: {
        primary: 'Konto deaktivieren',
        secondary: 'Schließen Sie Ihr Konto und heben Sie die Verknüpfung Ihrer Geräte auf.',
        enterPass: 'Passwort eingeben',
      },
      activeLanguage: 'Aktive Sprache',
      defaultLanguage: 'Systemsprache',
      privacyPolicy: {
        text: 'Datenschutzbestimmungen',
      },
      settings: 'Einstellungen',
      lang: {
        en: 'Englisch',
        it: 'Italienisch',
        de: 'Deutsch',
        esar: 'Spanisch (AR)',
        fr: 'Französisch',
        ja: 'Japanisch',
        ro: 'Rumänisch',
        da: 'Dänisch',
        nl: 'Niederländisch',
        ca: 'Katalanisch',
        lb: 'Luxemburgisch',
        no: 'Norwegisch',
        ptbr: 'Portugiesisch (BR)',
        zhcn: 'Chinesisch',
        ms: 'Malaiisch',
        ta: 'Tamilisch',
        bs: 'Bosnisch',
        bg: 'Bulgarisch',
        hr: 'Kroatisch',
        tr: 'Türkisch',
        cs: 'Tschechisch',
        et: 'Estnisch',
        fi: 'Finnisch',
        el: 'Griechisch',
        hu: 'Ungarisch',
        ga: 'Irisch',
        is: 'Isländisch',
        lv: 'Lettisch',
        lt: 'Litauisch',
        mt: 'Maltesisch',
        pl: 'Polnisch',
        pt: 'Portugiesisch (PT)',
        ru: 'Russisch',
        sr: 'Serbisch',
        sk: 'Slowakisch',
        sl: 'Slowenisch',
        sv: 'Schwedisch',
        mi: 'Māori',
        ar: 'Arabisch',
        af: 'Afrikaans',
        zhtw: 'Chinesisch (Traditionell)',
        es: 'Spanisch (ES)',
        ko: 'Koreanisch',
        enus: 'Englisch (Vereinigte Staaten)',
        deat: 'Deutsch (Österreich)',
        dech: 'Deutsch (Schweiz)',
        deli: 'Deutsch (Liechtenstein)',
        dede: 'Deutsch (Deutschland)',
        enca: 'Englisch (Kanada)',
        engb: 'Englisch',
        enie: 'Englisch (Irland)',
        ennz: 'Englisch (Neuseeland)',
        enza: 'Englisch (Südafrika)',
        esmx: 'Spanisch (Mexiko)',
        frca: 'Französisch (Kanada)',
        frch: 'Französisch (Schweiz)',
        itch: 'Italienisch (Schweiz)',
        jajp: 'Japanisch (Japan)',
        kokr: 'Koreanisch (Südkorea)',
        nb: 'Norwegisch Bokmål',
        nn: 'Norwegisch Nynorsk',
        zhch: 'Chinesisch (VR China)',
      },
      language: {
        primary: 'Sprache',
      },
    },
    show: {
      more: ' Mehr anzeigen',
      less: ' Weniger anzeigen',
    },
    showPage: {
      seasons: 'Staffeln',
      cast: 'Besetzung',
      producers: 'Produzenten',
      genre: 'Genres',
      directedBy: 'Regie',
      season: 'Staffel',
    },
    accountDetails: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      password: 'Passwort',
      changePassword: 'Passwort ändern',
      forgotPassword: 'Passwort vergessen',
    },
    childPinPopup: {
      title: 'Kindersicherung',
      subTitle: 'PIN für Kindersicherung erstellen (aktualisieren)',
      pinUpdated: 'PIN erfolgreich aktualisiert',
      pinIsIncorrect: 'PIN ist falsch',
    },
    deactivateAccount: {
      title: 'Sind Sie sicher, dass Sie Ihr Konto deaktivieren möchten?',
      description:
        'Sobald Sie dies tun, werden Sie von allen Diensten abgemeldet und können keine Inhalte mehr anzeigen.',
      deactivateButton: 'Konto deaktivieren',
    },
    servicesPage: {
      add: 'Zu Streaming-Diensten hinzufügen',
      remove: 'Aus Streaming-Diensten entfernen',
      overflow: {
        title: 'Ausgewählte Pakete',
        subtitle: 'Wählen Sie Ihre Dienste aus',
        description: 'Fügen Sie alle von Ihnen genutzten Dienste zu Ihrem Leitfaden hinzu.',
      },
    },
    manageProfile: {
      text: 'Profile verwalten',
    },
    watchlist: {
      title: 'Hier find Sie alle Filme und Serien, die Sie Ihrer Merkliste hinzugefügt haben',
      noTitles: 'Sie haben derzeit keine Titel in Ihrer Merkliste',
      addText:
        'Klicken Sie auf + Zur Merkliste hinzufügen, wenn Sie eine Sendung zu einem späteren Zeitpunkt ansehen möchten.',
      subtext: {
        start: 'Klicken Sie auf ',
        toWatchList: 'Zur Merkliste hinzufügen,',
        end: 'wenn Sie eine Sendung zu einem späteren Zeitpunkt ansehen möchten.',
      },
    },
    streamingService: {
      title: 'Streaming-Dienste auswählen',
      subTitle: 'Fügen Sie die von Ihnen abonnierten Dienste zu Ihrem TV-Programm hinzu.',
      streamingServices: 'Streaming-Dienste',
    },
    profileUpdate: {
      backToSettings: 'Zurück zu Einstellungen',
      updatedSuccess: 'Benutzer wurde erfolgreich aktualisiert',
      apply: 'Anwenden',
      wrongPassword: 'Falsches Passwort',
    },
    changePasswordPage: {
      backToSettings: 'Zurück zu Einstellungen',
      currentPass: 'Aktuelles Passwort',
      newPass: 'Neues Passwort',
      confirmPass: 'Passwort bestätigen',
      change: 'Ändern',
      changeUserInfo: 'Benutzerinformationen ändern',
      passwordDoNotMatch: 'Passwörter stimmen nicht überein oder sind ungültig',
      passwordUpdated: 'Passwort erfolgreich aktualisiert',
    },
    noServices: {
      title: 'Ihr TV-Programm enthält zurzeit keine Dienste',
      text: 'Um das Beste aus ScreenHits TV herauszuholen, müssen Sie Streaming-Dienste hinzufügen. Bitte klicken Sie hier, um Ihre bereits abonnierten Dienste ohne zusätzliche Kosten hinzuzufügen. Und falls sie aktuell keine Streaming-Dienste abonniert haben, richten Sie hier unser KOSTENLOSES Paket ein, um ab sofort tolle Inhalte von ZDF, Joyn und SHTV zu entdecken.',
      button: 'Dienste hinzufügen',
    },
    deleteProfile: {
      cantDeleteProfile: 'Sie können Ihr aktuelles Profil nicht löschen',
      areYouSure: 'Sind Sie sicher, dass Sie dieses Profil löschen wollen?',
      delete: 'Löschen',
    },
    notFoundPage: {
      title: 'Da hat etwas nicht geklappt.',
      text: 'Aber keine Sorge, es ist alles okay. Wir bringen diese Seite zurück.',
      home: 'Startseite',
    },
    noInternet: {
      title: 'Keine Internetverbindung',
      description: 'Keine Sorge, alles ist in Ordnung. Wir werden mit dieser Seite zurückkommen.',
      button: 'Neu laden',
    },
  },
};

export default DE_AT_RESOURCE;
