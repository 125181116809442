import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "routes/AppRoutes";
import { AppStoreProvider } from "store";
import Theme from "./Theme";
import SWRConfigWrapper from "components/wrappers/SWRConfigWrapper";
import { Suspense, useEffect } from "react";
import BackdropLoader from "components/Loaders/BackdropLoader";
import UserDataWrapper from "./UserDataWrapper";
import "./styles.css";
import ErrorBoundaryFn from "./ErrorBoundary";
import NoInternetWrapper from "./NoInternetWrapper";
import { getTokenFromCookies, removeTokenFromCookies } from "helpers/cookies";

import jwt_decode from "jwt-decode";
import { appLinks } from "routes/routes";
import { getUserDataFromLocalStorage } from "helpers/localStorage";

const access_token = getTokenFromCookies();
const user_data = getUserDataFromLocalStorage();

const App: React.FC = (): JSX.Element => {
  useEffect(() => {
    if (access_token && user_data) {
      const decoded = jwt_decode<{ exp: number }>(access_token);
      if (decoded.exp < Date.now() / 1000) {
        removeTokenFromCookies();

        location.replace(appLinks.loginWithQR.link);
      }
    }
  }, []);

  return (
    <SWRConfigWrapper>
      <AppStoreProvider>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <Theme>
              <UserDataWrapper>
                <CssBaseline />
                {/* <ParentControl /> */}
                <Suspense fallback={<BackdropLoader />}>
                  <NoInternetWrapper>
                    <ErrorBoundaryFn>
                      <AppRoutes />
                    </ErrorBoundaryFn>
                  </NoInternetWrapper>
                </Suspense>
              </UserDataWrapper>
            </Theme>
          </SnackbarProvider>
        </BrowserRouter>
      </AppStoreProvider>
    </SWRConfigWrapper>
  );
};

export default App;
