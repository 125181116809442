export interface GenreListItem {
  id: number;
  name: string;
  isHeader: boolean;
  order: number;
}

export type GenreListData = GenreListItem[];

export type GenreListResponse = Promise<GenreListData>;

export enum OtherGenreFilterEnum {
  FILMS = 'films',
  SERIES = 'series',
}
