import { alpha, createTheme } from '@mui/material';
import colors from './colors';

import MenuOverlay from 'assets/images/menu-overlay-bg.svg';

export const createMUITheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.white,
      secondary: colors.gray,
    },
  },
  typography: {
    fontFamily: ['acumin-pro', 'sans-serif', 'Montserrat'].join(','),

    h1: {
      marginBottom: ' 1.25rem',
      fontSize: '23px',
      fontWeight: 800,
    },
    body1: {
      fontSize: '20px',
      fontWeight: 300,
      '@media (max-width:380px)': {
        fontSize: 14,
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: colors.white,
          fontFamily: 'acumin-pro, sans-serif',
          backgroundColor: colors.secondary,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '& .MuiIconButton-root': {
            color: colors.white,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(colors.gray, 0.22),
          borderColor: alpha(colors.gray, 0.22),
          borderRadius: '10px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: colors.primary,
          },
        },
        input: {
          boxShadow: `0 1px 2px 1px ${alpha(colors.black, 0.07)}`,
          padding: '17px 0px',
          textAlign: 'center',
          color: colors.white,
          fontSize: '20px',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        text: {
          fontWeight: 500,
          color: colors.btnLinkLight,
          '&:hover': {
            opacity: '0.8',
          },
          '@media (max-width:600px)': {
            fontSize: 14,
          },
        },
        outlined: {
          fontWeight: 500,
          borderColor: colors.primary,
          '&:disabled': {
            border: `1px solid ${colors.gray}`,
            color: `${colors.gray}!important`,
          },
        },
        outlinedSizeLarge: {
          color: colors.white,
          minWidth: 160,
          padding: '16px 47px',
          fontSize: 16,
          ':disabled': {
            color: colors.primary,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.gray,
          textDecorationColor: colors.gray,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: alpha(colors.gray, 0.7),
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          padding: '0px',
          justifyContent: 'space-between',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            marginTop: '10px',
            borderRadius: '10px',
            backdropFilter: '28px',
            backgroundColor: alpha(colors.secondary, 0.6),
          },
          list: {
            root: {
              color: colors.gray,
              fontSize: '16px',
              '&:hover': {
                cursor: 'pointer',
                color: colors.primary,
              },
            },
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        anchor: 'left',
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: `url(${MenuOverlay})`,
          backgroundPosition: '0 100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
        paper: ({ theme }) => ({
          justifyContent: 'center',
          borderRight: 'none',
          backgroundColor: 'transparent',
          padding: theme.spacing(2),
        }),
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: colors.darkGray,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: 20,
          color: colors.white,
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        root: {
          marginBottom: 10,
        },
      },
    },
  },
});
