import * as ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import './helpers/i18n';
import TagManager from 'react-gtm-module';

const gtmId = process.env.REACT_APP_GOOGLE_ANALYTICS_GTM_ID as string;

const tagManagerArgs = {
  gtmId,
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
// eslint-disable-next-line
const root = ReactDOM.createRoot(container!);

root.render(
  <>
    <App />
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
