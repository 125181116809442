import { Box } from '@mui/material';

import AnimatedLogo from 'assets/images/LogoAnimated.gif';

const ProgressLoader: React.FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        zIndex: 0,
        width: 1,
        height: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          component='img'
          src={AnimatedLogo}
          sx={{
            width: '300px',
          }}
        />
      </Box>
    </Box>
  );
};

export default ProgressLoader;
