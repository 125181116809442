const IS_RESOURCE = {
  translation: {
    loading: 'Hleðsla',
    search: {
      cancel: 'Hætta við',
      noResults:
        'Engar leitarniðurstöður fundust, en þær hér að neðan eru svo sannarlega þess virði að skoða.',
    },
    menu: {
      switchProfiles: 'Breyta prófíl',
      settings: 'Stillingar',
      close: 'Loka',
      logOut: 'Útskráning',
    },
    button: {
      apply: 'Sækja um',
      continue: 'Halda áfram',
      cancel: 'Hætta við',
      confirm: 'Staðfesta',
      play: 'Spila',
      addToWatchList: 'Bæta við áhorfslista',
      removeFromWatchList: 'Fjarlægja af áhorfslista',
      moreInfo: 'Meiri upplýsingar',
      resume: 'Halda áfram',
      close: 'Loka',
    },
    login: {
      header: 'Innskráning reiknings',
      title: 'Velkomin til',
      subTitle:
        'Fáðu sem mest út úr streymisáskriftunum þínum með Netflix, Disney, Amazon og fleiru.',
      text: 'Allt sem þú vilt sjá á einum stað.',
      button: 'Innskráning',
      placeholder: {
        username: 'Notandanafn',
        password: 'Lykilorð',
      },
      errors: {
        email: {
          noValid: 'Netfangið verður að vera gilt netfang',
          required: 'Netfanger áskilinn reitur',
        },
        password: {
          required: 'Lykilorð er áskilinn reitur',
        },
        wrongPass: {
          first: 'Úbbs, þetta var ekki rétt lykilorð. Vinsamlegast reyndu aftur eða smelltu á ',
          second: '“Gleymt lykilorð” ',
          third: 'hnappinn fyrir neðan.',
        },
      },
    },
    forgotPassword: {
      message:
        'Ekki hafa áhyggjur. Það er auðvelt að endurstilla lykilorðið þitt. Sláðu bara inn netfangið þitt hér að neðan og smelltu á „SENDA"',
      button: 'SENDA',
      placeholder: {
        username: 'Notandanafn',
      },
      errors: {
        email: {
          required: 'Netfanger áskilinn reitur',
        },
        emailNotRegister: 'Netfang ekki skráð!',
        emailSent: 'Tölvupóstur til að endurstilla lykilorð sendur!',
      },
    },
    profilesPage: {
      title: 'Veldu prófíl',
      subTitle:
        'Allir í bílnum þínu geta haft sérhannaða sjónvarpsupplifun. Bættu við nýju prófíli.',
      newProfile: 'Nýr prófíll',
    },
    newProfile: {
      isKid: 'Krakki?',
      loading: 'Hleðsla',
      profileCreated: 'Prófíll búinn til!',
      profileSelection: 'Prófílval',
    },
    settings: {
      accountDetails: {
        primary: 'Reikningsupplýsingar',
        secondary: 'Breyta nafni reikningshafa og netfangi.',
      },
      manageProfiles: {
        primary: 'Stjórna prófílum',
        secondary: 'Breyta upplýsingum',
      },
      parentControl: {
        primary: 'Foreldraeftirlit',
        secondary: 'Búa til PIN / Breyta PIN',
        instruction: '(Vinsamlega sláðu inn 4 tölustafi á meðan lyklaborðið er opið)',
        recoverPin: 'Endurheimta PIN-númer',
        pinSentTo: 'PIN-númer sent á',
      },
      membershipStatus: {
        primary: 'Aðildarstaða',
        secondary: 'Virk',
      },
      deactivateAccount: {
        primary: 'Slökktu á reikningi',
        secondary: 'Lokaðu reikningnum þínum og aftengdu tækin þín.',
        enterPass: 'Sláðu inn lykilorðið þitt',
      },
      activeLanguage: 'Virkt tungumál',
      defaultLanguage: 'Kerfismál',
      privacyPolicy: {
        text: 'Friðhelgisstefna',
      },
      settings: 'Stillingar',
      lang: {
        en: 'Enska',
        it: 'Ítalska',
        de: 'Þýska',
        esar: 'Spænska(Argentína)',
        fr: 'Franska',
        ja: 'Japanska',
        ro: 'Rúmenska',
        da: 'Danska',
        nl: 'Hollenska',
        ca: 'Katalónska',
        lb: 'Lúxemborgíska',
        no: 'Norska',
        ptbr: 'Portúgalska(Brasilía)',
        zhcn: 'Kínverska',
        ms: 'Malasíska',
        ta: 'Tamil',
        bs: 'Bosníska',
        bg: 'Búlgarska',
        hr: 'Króatískt',
        tr: 'Tyrkneska',
        cs: 'Tékknesk',
        et: 'Eistneska',
        fi: 'Finnska',
        el: 'Gríska',
        hu: 'Ungverska',
        ga: 'Írska',
        is: 'Íslenska',
        lv: 'Lettneska',
        lt: 'Litháíska',
        mt: 'Maltneska',
        pl: 'Pólska',
        pt: 'Portúgalska(Portúgal)',
        ru: 'Rússneska',
        sr: 'Serbneska',
        sk: 'Slóvakíska',
        sl: 'Slóvenska',
        sv: 'Sænska',
        mi: 'Márí',
        ar: 'Arabíska',
        af: 'Afrikaans',
        zhtw: 'Kínverska(Hefðbundið)',
        es: 'Spænska(Spánn)',
        ko: 'Kóreska',
        enus: 'Enska (Bandaríkin)',
        deat: 'Þýska (Austurríki)',
        dech: 'Þýska (Sviss)',
        deli: 'Þýska (Liechtenstein)',
        dede: 'Þýska (Þýskaland)',
        enca: 'Enska (Kanada)',
        engb: 'Enska',
        enie: 'Enska (Írland)',
        ennz: 'Enska (Nýja-Sjáland)',
        enza: 'Enska (Suður-Afríka)',
        esmx: 'Spænska (Mexíkó)',
        frca: 'Franska (Kanada)',
        frch: 'Franska (Sviss)',
        itch: 'Ítalska (Sviss)',
        jajp: 'Japanska (Japan)',
        kokr: 'Kóreska (Suður-Kórea)',
        nb: 'Norska (Bokmål)',
        nn: 'Norska (Nynorsk)',
        zhch: 'Kínverska (Lýðveldið Kína)',
      },
      language: {
        primary: 'Tungumál',
      },
    },
    show: {
      more: ' Sýndu meira',
      less: ' Sýna minna',
    },
    showPage: {
      seasons: 'Árstíðir',
      cast: 'Leikarar',
      producers: 'Framleiðendur',
      genre: 'Tegund',
      directedBy: 'Leikstýrt af',
      season: 'þáttaröð',
    },
    accountDetails: {
      firstName: 'Fornafn',
      lastName: 'Eftirnafn',
      email: 'Netfang',
      password: 'Lykilorð',
      changePassword: 'Breyta lykilorði',
      forgotPassword: 'Gleymt lykilorð',
    },
    childPinPopup: {
      title: 'Barnalæsing',
      subTitle: 'Búðu til (uppfærðu) PIN-númer fyrir barnalæsingu',
      pinUpdated: 'PIN-númerið var uppfært',
      pinIsIncorrect: 'PIN-númerið er rangt',
    },
    deactivateAccount: {
      title: 'Ertu viss um að þú viljir gera reikninginn þinn óvirkan?',
      description:
        'Þegar þú hefur gert það verður þú skráð(ur) út af allri þjónustu og munt ekki lengur geta skoðað efni í bílnum þínum',
      deactivateButton: 'Gera reikning óvirkan',
    },
    servicesPage: {
      add: 'Bæta við þjónustu',
      remove: 'Fjarlægja úr þjónustu',
      overflow: {
        title: 'Valdir pakkar',
        subtitle: 'Veldu þjónustu þína',
        description: 'Bættu hverri þjónustu sem þú notar við dagskrána.',
      },
    },
    manageProfile: {
      text: 'Stjórna prófílum',
    },
    watchlist: {
      title: 'Hér eru allar kvikmyndir og þættir sem þú hefur bætt við áhorfslistann',
      noTitles: 'Þú hefur enga titla á áhorfslistanum þínum eins og er',
      addText:
        'Til að bæta við, vinsamlegast smelltu á + Bæta við vaktlista á hvaða þætti sem þú vilt horfa aftur á og horfa á síðar.',
      subtext: {
        start: 'Til að bæta við, vinsamlegast smelltu á ',
        toWatchList: 'Bæta við áhorfslista',
        end: 'eða hvaða þætti sem þú vilt horfa aftur á og horfa á síðar.',
      },
    },
    streamingService: {
      title: 'Veldu streymisþjónustur',
      subTitle: 'Bættu við völdum þjónustum þínum til að birtast í sjónvarpshandbókinni þinni',
      streamingServices: 'Streymisþjónusta',
    },
    profileUpdate: {
      backToSettings: 'Til baka í Stillingar',
      updatedSuccess: 'Notandi var uppfærður',
      apply: 'Nota',
      wrongPassword: 'Rangt lykilorð',
    },
    changePasswordPage: {
      backToSettings: 'Til baka í Stillingar',
      currentPass: 'Núverandi lykilorð',
      newPass: 'Nýtt lykilorð',
      confirmPass: 'Staðfesta lykilorð',
      change: 'Breyta',
      changeUserInfo: 'Breyta notandaupplýsingum',
      passwordDoNotMatch: 'Lykilorð passa ekki eða eru ógild',
      passwordUpdated: 'Lykilorðið uppfært',
    },
    noServices: {
      title: 'Þú hefur enga þjónustu í sjónvarpsdagskránni þinni eins og er',
      text: 'Til að fá sem mest út úr ScreenHits TV þarftu að bæta við þjónustu. Vinsamlegast smelltu hér til að bæta við streymum sem þú ert nú þegar áskrifandi að án aukakostnaðar. Og ef þú ert ekki áskrifandi að neinni streymisþjónustu, bættu við ÓKEYPIS pakkanum okkar og byrjaðu að uppgötva frábært efni.',
      button: 'Bæta við þjónustu',
    },
    deleteProfile: {
      cantDeleteProfile: 'Þú getur ekki eytt núverandi prófílnum þínum',
      areYouSure: 'Ertu viss um að þú viljir eyða þessum prófíl?',
      delete: 'Eyða',
    },
    notFoundPage: {
      title: 'Eitthvað fór úrskeiðis',
      text: 'Ekki hafa áhyggjur, allt er í lagi. Við munum koma aftur með þessa síðu.',
      home: 'Heimasíða',
    },
    noInternet: {
      title: 'Engin nettenging við internetið',
      description: 'Ekki þurfuð að áhyggjast, allt er í lagi. Við munum koma til baka með þessari síðu.',
      button: 'Endurræsa',
    },
  },
};

export default IS_RESOURCE;
