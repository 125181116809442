import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { getMenuItems } from 'api/menuApi';
import NoMatchesIcons from 'components/icons/NoMatchesIcons';
import { useTranslation } from 'react-i18next';

const NoInternetPlaceholder: React.FC = () => {
  const { t } = useTranslation();

  const handleReloadPage = async () => {
    try {
      await getMenuItems();
      window.location.reload();
    } catch (e) {
      return;
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <Container
        maxWidth={'xs'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <NoMatchesIcons sx={{ fontSize: { xs: 150, md: 200 }, mb: { xs: 4, md: 6 } }} />

        <Typography variant='h1' textAlign='center'>
          {t('noInternet.title')}
        </Typography>
        <Typography variant='body1' textAlign='center'>
          {t('noInternet.description')}
        </Typography>
        <Button variant='outlined' size='large' sx={{ mt: 2 }} onClick={handleReloadPage}>
          {t('noInternet.button')}
        </Button>
      </Container>
    </Container>
  );
};

export default NoInternetPlaceholder;
