const LT_RESOURCE = {
  translation: {
    loading: 'Pakrovimas',
    search: {
      cancel: 'Atšaukti',
      noResults: 'Paieškos rezultatų nerasta, bet toliau pateiktus tikrai verta patikrinti.',
    },
    menu: {
      switchProfiles: 'Keisti profilį',
      settings: 'Nustatymai',
      close: 'Uždaryti',
      logOut: 'Atsijungti',
    },
    button: {
      apply: 'Taikyti',
      continue: 'Tęsti',
      cancel: 'Atšaukti',
      confirm: 'Patvirtinkite',
      play: 'Žaisti',
      addToWatchList: 'Pridėti į stebėjimo sąrašą',
      removeFromWatchList: 'Pašalinti iš stebėjimo sąrašo',
      moreInfo: 'Daugiau informacijos',
      resume: 'Atnaujinti',
      close: 'Uždaryti',
    },
    login: {
      header: 'Prisijungimas prie paskyros',
      title: 'Sveiki atvykę į',
      subTitle:
        'MMaksimaliai išnaudokite savo transliacijos prenumeratas su „Disney“, „Amazon“ ir kt.',
      text: 'Viskas, ką norite žiūrėti, vienoje vietoje.',
      button: 'Prisijungti',
      placeholder: {
        username: 'Vartotojo vardas',
        password: 'Slaptažodis',
      },
      errors: {
        email: {
          noValid: 'El. paštas turi būti galiojantis el. pašto adresas',
          required: 'El. paštas yra privalomas laukas',
        },
        password: {
          required: 'Slaptažodis yra privalomas laukas',
        },
        wrongPass: {
          first: 'Oi, tai nebuvo tinkamas slaptažodis. Bandykite dar kartą arba spustelėkite  ',
          second: '“Pamiršote slaptažodį” ',
          third: 'toliau esantis mygtukas.',
        },
      },
    },
    forgotPassword: {
      message:
        'Nesijaudinkite. Iš naujo nustatyti slaptažodį paprasta. Tiesiog įveskite toliau nurodytą el. pašto adresą ir spustelėkite „Siųsti“',
      button: 'Siųsti',
      placeholder: {
        username: 'Vartotojo vardas',
      },
      errors: {
        email: {
          required: 'El. paštas yra privalomas laukas',
        },
        emailNotRegister: 'El. pašto adresas neregistruotas!',
        emailSent: 'Išsiųstas slaptažodžio atstatymo el. laiškas!',
      },
    },
    profilesPage: {
      title: 'Pasirinkite profilį',
      subTitle:
        'Kiekvienas jūsų automobilio keleivis gali turėti asmeninį televizijos patyrimą. Pridėkite naują profilį.',
      newProfile: 'Naujas profilis',
    },
    newProfile: {
      isKid: 'Vaikas?',
      loading: 'Pakrovimas',
      profileCreated: 'Sėkmingai sukurtas profilis!',
      profileSelection: 'Profilio pasirinkimas',
    },
    settings: {
      accountDetails: {
        primary: 'Paskyros duomenys',
        secondary: 'Redaguoti paskyros turėtojo vardą ir el. pašto adresą.',
      },
      manageProfiles: {
        primary: 'Tvarkyti profilius',
        secondary: 'Redaguoti informaciją',
      },
      parentControl: {
        primary: 'Tėvų kontrolė',
        secondary: 'Sukurti PIN kodą / pakeisti PIN kodą',
        instruction: '(Įveskite 4 skaitmenis, kai klaviatūra atidaryta)',
        recoverPin: 'Atkurti PIN kodą',
        pinSentTo: 'PIN kodas išsiųstas',
      },
      membershipStatus: {
        primary: 'Narystės statusas',
        secondary: 'Aktyvus',
      },
      deactivateAccount: {
        primary: 'Deaktyvuoti paskyrą',
        secondary: 'Uždarykite paskyrą ir atjunkite įrenginius.',
        enterPass: 'Įveskite slaptažodį',
      },
      activeLanguage: 'Aktyvi kalba',
      defaultLanguage: 'Sistemos kalba',
      privacyPolicy: {
        text: 'Privatumo politika',
      },
      settings: 'Nustatymai',
      lang: {
        en: 'Anglų k.',
        it: 'Italų k.',
        de: 'Vokiečių k.',
        esar: 'Ispanų(Argentinos) k.',
        fr: 'Prancūzų k.',
        ja: 'Japonų k.',
        ro: 'Rumunų k.',
        da: 'Danų k.',
        nl: 'Olandų k.',
        ca: 'Katalonų k.',
        lb: 'Liuksemburgiečių k.',
        no: 'Norvegų k.',
        ptbr: 'Portugalų(Brazilijos) k.',
        zhcn: 'Kinų k.',
        ms: 'Malajų k.',
        ta: 'Tamilų k.',
        bs: 'Bosnių k.',
        bg: 'Bulgarų k.',
        hr: 'Kroatų k.',
        tr: 'Turkų k.',
        cs: 'Čekų k.',
        et: 'Estų k.',
        fi: 'Suomių k.',
        el: 'Graikų k.',
        hu: 'Vengrų k.',
        ga: 'Airių k.',
        is: 'Islandų k.',
        lv: 'Latvių k.',
        lt: 'Lietuvių k.',
        mt: 'Maltiečių k.',
        pl: 'Lenkų k.',
        pt: 'Portugalų(Portugalijos) k.',
        ru: 'Rusų k.',
        sr: 'Serbų k.',
        sk: 'Slovakų k.',
        sl: 'Slovėnų k.',
        sv: 'Švedų k.',
        mi: 'Maorių k.',
        ar: 'Arabų k.',
        af: 'Afrikanų k.',
        zhtw: 'Kinų(tradicinė) k.',
        es: 'Ispanų(Ispanija) k.',
        ko: 'Korėjiečių k.',
        enus: 'Anglų (Jungtinės Valstijos)',
        deat: 'Vokiečių (Austrija)',
        dech: 'Vokiečių (Šveicarija)',
        deli: 'Vokiečių (Liuksemburgas)',
        dede: 'Vokiečių (Vokietija)',
        enca: 'Anglų (Kanada)',
        engb: 'Anglų',
        enie: 'Anglų (Airija)',
        ennz: 'Anglų (Naujoji Zelandija)',
        enza: 'Anglų (Pietų Afrika)',
        esmx: 'Ispanų (Meksika)',
        frca: 'Prancūzų (Kanada)',
        frch: 'Prancūzų (Šveicarija)',
        itch: 'Italų (Šveicarija)',
        jajp: 'Japonų (Japonija)',
        kokr: 'Korėjiečių (Pietų Korėja)',
        nb: 'Norvegų (Bokmål)',
        nn: 'Norvegų (Nynorsk)',
        zhch: 'Kinų (Kinijos Liaudies Respublika)',
      },
      language: {
        primary: 'Kalba',
      },
    },
    show: {
      more: ' Rodyti daugiau',
      less: ' Rodyti mažiau',
    },
    showPage: {
      seasons: 'Sezonai',
      cast: 'Laidos',
      producers: 'Gamintojai',
      genre: 'Žanras',
      directedBy: 'Režisierius',
      season: 'Sezonai',
    },
    accountDetails: {
      firstName: 'Vardas',
      lastName: 'Pavardė',
      email: 'El. paštas',
      password: 'Slaptažodis',
      changePassword: 'Keisti slaptažodį',
      forgotPassword: 'Pamiršote slaptažodį',
    },
    childPinPopup: {
      title: 'Vaikų apsauga',
      subTitle: 'Sukurti (atnaujinti) vaikų apsaugos PIN kodą',
      pinUpdated: 'PIN kodas sėkmingai atnaujintas',
      pinIsIncorrect: 'PIN kodas klaidingas',
    },
    deactivateAccount: {
      title: 'Ar tikrai norite deaktyvuoti savo paskyrą?',
      description:
        'Kai tai padarysite, būsite išregistruoti iš visų paslaugų ir nebegalėsite peržiūrėti turinio savo automobilyje.',
      deactivateButton: 'Deaktyvuoti paskyrą',
    },
    servicesPage: {
      add: 'Pridėti prie paslaugų',
      remove: 'Pašalinti iš paslaugų',
      overflow: {
        title: 'Pasirinkti paketai',
        subtitle: 'Pasirinkite savo paslaugas',
        description: 'Pridėkite kiekvieną naudojamą paslaugą prie savo vadovo.',
      },
    },
    manageProfile: {
      text: 'Tvarkyti profilius',
    },
    watchlist: {
      title: 'Čia pateikiami visi filmai ir laidos, kuriuos įtraukėte į savo stebimų filmų sąrašą',
      noTitles: 'Šiuo metu jūsų stebimų laidų sąraše nėra pavadinimų',
      addText:
        'Jei norite įtraukti, spustelėkite „+ Įtraukti į sąrašą“ bet kurioje laidoje, prie kurios norite grįžti ir žiūrėti vėliau.',
      subtext: {
        start: 'Jei norite pridėti, spustelėkite ',
        toWatchList: 'Pridėti į stebėjimo sąrašą',
        end: 'arba bet kurią laidą, prie kurios norėtumėte grįžti ir žiūrėti vėliau.',
      },
    },
    streamingService: {
      title: 'Pasirinkite transliacijos paslaugas',
      subTitle: 'Pridėkite pasirinktas paslaugas, kad jos būtų rodomos TV gide',
      streamingServices: 'Srautinio perdavimo paslaugos',
    },
    profileUpdate: {
      backToSettings: 'Grįžti į nustatymus',
      updatedSuccess: 'Vartotojas sėkmingai atnaujintas',
      apply: 'Taikyti',
      wrongPassword: 'Klaidingas slaptažodis',
    },
    changePasswordPage: {
      backToSettings: 'Grįžti į nustatymus',
      currentPass: 'Dabartinis slaptažodis',
      newPass: 'Naujas slaptažodis',
      confirmPass: 'Patvirtinti slaptažodį',
      change: 'Keisti',
      changeUserInfo: 'Keisti vartotojo informaciją',
      passwordDoNotMatch: 'Slaptažodžiai nesutampa arba yra negaliojantys',
      passwordUpdated: 'Slaptažodis sėkmingai atnaujintas',
    },
    noServices: {
      title: 'Šiuo metu TV gide nėra jokių paslaugų',
      text: 'Norėdami išnaudoti visas „ScreenHits TV“ galimybes, turite pridėti paslaugų. Spustelėkite čia, kad pridėtumėte jau prenumeruojamus srautinius kanalus be papildomų mokesčių. Ir jei šiuo metu neužsiprenumeruojate jokios srautinio perdavimo paslaugos, pridėkite mūsų NEMOKAMĄ paketą ir pradėkite atrasti puikų turinį.',
      button: 'Pridėti paslaugas',
    },
    deleteProfile: {
      cantDeleteProfile: 'Dabartinio profilio ištrinti negalite',
      areYouSure: 'Ar tikrai norite ištrinti šį profilį?',
      delete: 'Ištrinti',
    },
    notFoundPage: {
      title: 'Kažkas nutiko',
      text: 'Tačiau nesijaudinkite, viskas gerai. Su šiuo puslapiu sugrįšime.',
      home: 'Pagrindinis puslapis',
    },
    noInternet: {
      title: 'Nėra interneto ryšio',
      description: 'Nesijaudinkite, viskas gerai. Sugrįšime su šia puslapiu.',
      button: 'Atnaujinti',
    },
  },
};

export default LT_RESOURCE;
