import { UserData } from './../types/Auth';
import { ProfileData } from './../types/Profile';
const LocalStorageEnum = {
  SELECTED_PROFILE: 'screen_hits_selected_profile_data',
  SET_USER_DATA: 'screen_hits_user_data',
  LOCATION: 'screen_hits_user_location',
  SESSION_ID: 'screen_hits_session_id',
  SEARCH_Q: 'screen_hits_search_query',
};

const getSelectedProfileFromLocalStorage = (): ProfileData | null =>
  localStorage.getItem(LocalStorageEnum.SELECTED_PROFILE) as unknown as ProfileData;

const setSelectedProfileToLocalStorage = (profileData: ProfileData) =>
  localStorage.setItem(LocalStorageEnum.SELECTED_PROFILE, JSON.stringify(profileData));

const removeSelectedProfileFromLocalStorage = () =>
  localStorage.removeItem(LocalStorageEnum.SELECTED_PROFILE);

const getUserDataFromLocalStorage = (): UserData | null =>
  localStorage.getItem(LocalStorageEnum.SET_USER_DATA) as unknown as UserData;

const setUserDataToLocalStorage = (userData: UserData) =>
  localStorage.setItem(LocalStorageEnum.SET_USER_DATA, JSON.stringify(userData));

const removeUserDataFromLocalStorage = () =>
  localStorage.removeItem(LocalStorageEnum.SET_USER_DATA);

const getLocationFromLocalStorage = (): string | null =>
  localStorage.getItem(LocalStorageEnum.LOCATION);

const setLocationTpLocalStorage = (location: string) =>
  localStorage.setItem(LocalStorageEnum.LOCATION, location);

const removeLocationFromLocalStorage = () => localStorage.removeItem(LocalStorageEnum.LOCATION);

const setSessionIdToLocalStorage = (sessionId: string) =>
  localStorage.setItem(LocalStorageEnum.SESSION_ID, sessionId);

const getSessionIdFromLocalStorage = () => localStorage.getItem(LocalStorageEnum.SESSION_ID);

const removeSessionIdFromLocalStorage = () => localStorage.removeItem(LocalStorageEnum.SESSION_ID);

const setSearchQToLocalStorage = (q: string) => localStorage.setItem(LocalStorageEnum.SEARCH_Q, q);

const getSearchQFromLocalStorage = () => localStorage.getItem(LocalStorageEnum.SEARCH_Q);

const deleteSearchQFromLocalStorage = () => localStorage.removeItem(LocalStorageEnum.SEARCH_Q);

export {
  getSelectedProfileFromLocalStorage,
  setSelectedProfileToLocalStorage,
  removeSelectedProfileFromLocalStorage,
  getUserDataFromLocalStorage,
  setUserDataToLocalStorage,
  removeUserDataFromLocalStorage,
  getLocationFromLocalStorage,
  setLocationTpLocalStorage,
  removeLocationFromLocalStorage,
  setSessionIdToLocalStorage,
  getSessionIdFromLocalStorage,
  removeSessionIdFromLocalStorage,
  setSearchQToLocalStorage,
  getSearchQFromLocalStorage,
  deleteSearchQFromLocalStorage,
};
