const TA_RESOURCE = {
  translation: {
    loading: 'ஏற்றுகிறத',
    search: {
      cancel: 'ரத்துசெய்',
      noResults: 'தேடல் முடிவுகள் ஏதுமில்லை, ஆனால் கீழுள்ளவை உங்களுக்கு உதவும்.',
    },
    menu: {
      switchProfiles: 'சுயவிவரங்களை மாற்று',
      settings: 'அமைப்புகள்',
      close: 'மூடுக',
      logOut: 'வெளியேறு',
    },
    button: {
      apply: 'பயன்படுத்து',
      continue: 'தொடர்க',
      cancel: 'ரத்துசெய்',
      confirm: 'உறுதிசெய்',
      play: 'பிளே செய்',
      addToWatchList: 'பார்க்க விரும்புபவை பட்டியலில் சேர்',
      removeFromWatchList: 'பார்க்க விரும்புபவை பட்டியலில் இருந்து அகற்று',
      moreInfo: 'மேலும் தகவல்',
      resume: 'மீண்டும் தொடங்கு',
      close: 'மூடுக',
    },
    login: {
      header: 'கணக்கு உள்நுழைவு',
      title: 'வரவேற்கிறோம்',
      subTitle:
        'Disney, Amazon மற்றும் பல ஸ்ட்ரீமிங் சேவைகளின் சந்தாக்களில் இருந்து சிறந்த பலன்களைப் பெறுங்கள்.',
      text: 'அனைத்தையும் ஒரே இடத்தில் பார்க்கலாம்.',
      button: 'உள்நுழை',
      placeholder: {
        username: 'பயனர் பெயர்',
        password: 'கடவுச்சொல்',
      },
      errors: {
        email: {
          noValid: 'செல்லுபடியாகும் மின்னஞ்சல் முகவரியை உள்ளிடவும்',
          required: 'மின்னஞ்சல் முகவரி தேவை',
        },
        password: {
          required: 'கடவுச்சொல் தேவை',
        },
        wrongPass: {
          first: 'அடடா, இது சரியான கடவுச்சொல் இல்லை. மீண்டும் முயலவும் அல்லது கீழேயுள்ள ',
          second: '"கடவுச்சொல் மறந்துவிட்டது” பட்டனைக் " ',
          third: 'கிளிக் செய்யவும்.',
        },
      },
    },
    forgotPassword: {
      message:
        'கவலை வேண்டாம். கடவுச்சொல்லை எளிதாக மீட்டமைக்கலாம். கீழே உங்கள் மின்னஞ்சல் முகவரியை உள்ளிட்டு, "அனுப்பு” என்பதைக் கிளிக் செய்யவும் ',
      button: 'அனுப்பு',
      placeholder: {
        username: 'பயனர் பெயர்',
      },
      errors: {
        email: {
          required: 'மின்னஞ்சல் முகவரி தேவை',
        },
        emailNotRegister: 'மின்னஞ்சல் முகவரி பதிவுசெய்யப்படவில்லை!',
        emailSent: 'கடவுச்சொல்லை மீட்டமைப்பதற்கான மின்னஞ்சல் அனுப்பப்பட்டது!',
      },
    },
    profilesPage: {
      title: 'பார்ப்பது யார்?',
      subTitle:
        'உங்கள் காரிலுள்ள அனைத்துவரும் ஒரு கருத்துரையாளராக ஒரு கருத்துரையாளர் அனுபவம் கொண்டிருக்கலாம். புதிய சுயசரிதாவை சேர்க்கவும்.',
      newProfile: 'புதிய சுயவிவரம்',
    },
    newProfile: {
      isKid: 'பிள்ளைகள் சுயவிவரமா?',
      loading: 'ஏற்றுகிறது',
      profileCreated: 'சுயவிவரம் உருவாக்கப்பட்டது!',
      profileSelection: 'சுயவிவரத் தேர்வு',
    },
    settings: {
      accountDetails: {
        primary: 'கணக்கு விவரங்கள்',
        secondary: 'கணக்கு வைத்திருப்பவரின் பெயரையும் மின்னஞ்சல் முகவரியையும் திருத்தவும்.',
      },
      manageProfiles: {
        primary: 'சுயவிவரங்களை நிர்வகியுங்கள்',
        secondary: 'விவரங்களைத் திருத்து',
      },
      parentControl: {
        primary: 'பெற்றோர் கட்டுப்பாடு',
        secondary: 'PINஐ உருவாக்கு/மாற்று',
        instruction: '(கீபோர்டு காட்டப்படும்போது 4 இலக்கங்களை உள்ளிடவும்)',
        recoverPin: 'PINஐ மீட்டெடு',
        pinSentTo: 'PIN இதற்கு அனுப்பப்பட்டது:',
      },
      membershipStatus: {
        primary: 'மெம்பர்ஷிப் நிலை',
        secondary: 'செயலில் உள்ளது',
      },
      deactivateAccount: {
        primary: 'கணக்கைச் செயல்நீக்கு',
        secondary: 'கணக்கை மூடிவிட்டு, சாதனங்களின் இணைப்பை நீக்கு',
        enterPass: 'உங்கள் கடவுச்சொல்லை உள்ளிடவும்',
      },
      activeLanguage: 'செயலில் உள்ள மொழி',
      defaultLanguage: 'சிஸ்டம் மொழி',
      privacyPolicy: {
        text: 'தனியுரிமைக் கொள்கை',
      },
      settings: 'அமைப்புகள்',
      lang: {
        en: 'ஆங்கிலம்',
        it: 'இத்தாலியன்',
        de: 'ஜெர்மன்',
        esar: 'ஸ்பானிஷ் (அர்ஜென்டினா)',
        fr: 'ஃபிரெஞ்சு',
        ja: 'ஜாப்பனீஸ்',
        ro: 'ரோமானியன்',
        da: 'டேனிஷ்',
        nl: 'டச்சுு',
        ca: 'கேட்டலன்',
        lb: 'லக்ஸெம்பர்கிஷ்',
        no: 'நார்வேஜியன்',
        ptbr: 'போர்ச்சுகீஸ் (பிரேசில்)',
        zhcn: 'சீனம்',
        ms: 'மலாய்',
        ta: 'தமிழ்',
        bs: 'போஸ்னியன்',
        bg: 'பல்கேரியன்',
        hr: 'குரோஷியன்',
        tr: 'டர்கிஷ்',
        cs: 'செக்',
        et: 'எஸ்டோனியன்',
        fi: 'ஃபின்னிஷ்',
        el: 'கிரீக்',
        hu: 'ஹங்கேரியன்',
        ga: 'ஐரிஷ்',
        is: 'ஐஸ்லாண்டிக்',
        lv: 'லாட்வியன்',
        lt: 'லிதுவேனியன்',
        mt: 'மால்டீஸ்',
        pl: 'போலிஷ்',
        pt: 'போர்ச்சுககீஸ் (போர்ச்சுகல்)',
        ru: 'ரஷ்யன்',
        sr: 'செர்பியன்',
        sk: 'ஸ்லோவாக்',
        sl: 'ஸ்லோவேனியன்',
        sv: 'ஸ்வீடிஷ்',
        mi: 'மாவோரி',
        ar: 'அரபிக்',
        af: 'ஆஃப்ரிகான்ஸ்',
        zhtw: 'சீனம் (மரபுவழி)',
        es: 'ஸ்பானிஷ் (ஸ்பெயின்)',
        ko: 'கொரியன்',
        enus: 'ஆங்கிலம் (ஐக்கிய அமெரிக்கா)',
        deat: 'ஜெர்மன் (ஆஸ்திரியா)',
        dech: 'ஜெர்மன் (சுவிட்சர்லாந்த)',
        deli: 'ஜெர்மன் (லிச்டென்ஸ்டைன்)',
        dede: 'ஜெர்மன் (ஜெர்மனி)',
        enca: 'ஆங்கிலம் (கனடா)',
        engb: 'ஆங்கிலம்',
        enie: 'ஆங்கிலம் (அயர்லாந்து)',
        ennz: 'ஆங்கிலம் (நியூசிலாந்து)',
        enza: 'ஆங்கிலம் (தென் ஆப்ரிக்கா)',
        esmx: 'ஸ்பானிஷ் (மெக்சிகோ)',
        frca: 'பிரெஞ்சு (கனடா)',
        frch: 'பிரெஞ்சு (சுவிட்சர்லாந்த)',
        itch: 'இத்தாலியன் (சுவிட்சர்லாந்த)',
        jajp: 'ஜப்பானியம் (ஜப்பான்)',
        kokr: 'கொரியன் (தென் கொரியா)',
        nb: 'நார்வேஜியன் (போக்மால்)',
        nn: 'நார்வேஜியன் (நினோர்ஸ்க)',
        zhch: 'சீன (பி.ஆர்.சி.)',
      },
      language: {
        primary: 'மொழி',
      },
    },
    show: {
      more: ' மேலும் காட்டு',
      less: ' குறைவாகக் காட்டு',
    },
    showPage: {
      seasons: 'சீசன்கள்',
      cast: 'நடிகர்கள்',
      producers: 'தயாரிப்பாளர்கள்',
      genre: 'வகை',
      directedBy: 'இயக்குநர்',
      season: 'சீசன்',
    },
    accountDetails: {
      firstName: 'பெயரின் முற்பகுதி',
      lastName: 'பெயரின் பிற்பகுதி',
      email: 'மின்னஞ்சல் முகவரி',
      password: 'கடவுச்சொல்',
      changePassword: 'கடவுச்சொல்லை மாற்று',
      forgotPassword: 'கடவுச்சொல் மறந்துவிட்டது',
    },
    childPinPopup: {
      title: 'குழந்தைப் பாதுகாப்பு',
      subTitle: 'குழந்தைப் பாதுகாப்புக்கான PINஐ உருவாக்கு (மாற்று).',
      pinUpdated: 'PIN மாற்றப்பட்டது',
      pinIsIncorrect: 'PIN தவறானது',
    },
    deactivateAccount: {
      title: 'உங்கள் கணக்கை உறுதியாகச் செயல்நீக்க விரும்புகிறீர்களா?',
      description:
        'செயல்நீக்கப்பட்டதும், அனைத்துச் சேவைகளில் இருந்தும் வெளியேற்றப்படுவீர்கள். மேலும் உங்கள் காரில் உள்ளடக்கங்கள் எதையும் பார்க்க முடியாது',
      deactivateButton: 'கணக்கைச் செயல்நீக்கு',
    },
    servicesPage: {
      add: 'சேவைகளுடன் சேர்',
      remove: 'சேவைகளில் இருந்து நீக்கு',
      overflow: {
        title: 'தேர்ந்தெடுக்கப்பட்ட தொகுப்புகள்',
        subtitle: 'உங்கள் சேவைகளைத் தேர்ந்தெடுக்கவும்',
        description: 'உங்கள் வழிகாட்டிக்குப் பயன்படுத்தும் ஒவ்வொரு சேவையையும் சேர்க்கவும்.',
      },
    },
    manageProfile: {
      text: 'சுயவிவரங்களை நிர்வகியுங்கள்',
    },
    watchlist: {
      title:
        'பார்க்க விரும்புபவை பட்டியலில் நீங்கள் சேர்த்துள்ள அனைத்துப் படங்கும் நிகழ்ச்சிகளும் இங்கேயுள்ளன. ',
      noTitles: 'பார்க்க விரும்புபவை பட்டியலில் நீங்கள் எதையும் சேர்க்கவில்லை',
      addText:
        'பிறகு பார்ப்பதற்காகப் பட்டியலில் நிகழ்ச்சிகளைச் சேர்க்க, அவற்றில் காட்டப்படும் + பார்க்க விரும்புபவை பட்டியலில் சேர் என்பதைக் கிளிக் செய்யவும்.',
      subtext: {
        start: 'பிறகு பார்ப்பதற்காகப் பட்டியலில் நிகழ்ச்சிகளைச் சேர்க்க, அவற்றில் காட்டப்படும் +  ',
        toWatchList: 'பார்க்க விரும்புபவை பட்டியலில் சேர்',
        end: 'என்பதைக் கிளிக் செய்யவும்.',
      },
    },
    streamingService: {
      title: 'ஸ்ட்ரீமிங் சேவைகளைத் தேர்ந்தெடுக்கவும்',
      subTitle:
        'உங்கள் டிவி வழிகாட்டியில் காட்டப்படுவதற்கு, தேர்ந்தெடுக்கப்பட்ட சேவையைச் சேர்க்கவும்.',
      streamingServices: 'ஸ்ட்ரீமிங் சேவைகள்',
    },
    profileUpdate: {
      backToSettings: 'அமைப்புகளுக்குச் செல்',
      updatedSuccess: 'பயனர் விவரங்கள் புதுப்பிக்கப்பட்டன',
      apply: 'பயன்படுத்து',
      wrongPassword: 'தவறான கடவுச்சொல்',
    },
    changePasswordPage: {
      backToSettings: 'அமைப்புகளுக்குச் செல்',
      currentPass: 'தற்போதைய கடவுச்சொல்',
      newPass: 'புதிய கடவுச்சொல்',
      confirmPass: 'கடவுச்சொல்லை உறுதிப்படுத்துக',
      change: 'மாற்று',
      changeUserInfo: 'பயனர் தகவலை மாற்று',
      passwordDoNotMatch: 'கடவுச்சொற்கள் பொருந்தவில்லை அல்லது தவறாக உள்ளன',
      passwordUpdated: 'கடவுச்சொல் மாற்றப்பட்டது',
    },
    noServices: {
      title: 'உங்கள் டிவி வழிகாட்டியில் எந்தச் சேவைகளும் சேர்க்கப்படவில்லை.',
      text: 'ScreenHits TV மூலம் அதிகப் பலன்களைப் பெற, சேவைகளை நீங்கள் சேர்க்க வேண்டும். நீங்கள் ஏற்கனவே சந்தா பெற்றுள்ள ஸ்ட்ரீமர்களை எந்தவொரு கூடுதல் கட்டணமுமின்றி சேர்க்க இங்கே கிளிக் செய்யுங்கள். நீங்கள் இதுவரை எந்தவொரு ஸ்ட்ரீமிங் சேவைக்கும் சந்தா பெறவில்லை எனில், எங்களின் இலவசத் தொகுப்பை இங்கே சேர்த்து, BBC, ITV, My5, Channel 4, UKTV, STV போன்ற சேனல்களில் இருந்து சிறந்தவற்றைப் பார்க்கலாம்.',
      button: 'சேவைகளைச் சேர்',
    },
    deleteProfile: {
      cantDeleteProfile: 'உங்களின் தற்போதைய சுயவிவரத்தை நீக்க முடியாது',
      areYouSure: 'இந்தச் சுயவிவரத்தை உறுதியாக நீக்க விரும்புகிறீர்களா?',
      delete: 'நீக்குி',
    },
    notFoundPage: {
      title: 'ஏதோ தவறாகிவிட்டது',
      text: 'கவலைப்பட வேண்டாம், எல்லாம் சரியாக உள்ளது. இந்தப் பக்கத்துடன் மீண்டும் வருவோம்.',
      home: 'முகப்பு',
    },
    noInternet: {
      title: 'இணைய இணைப்பு இல்லை',
      description: 'ஆனால் சிந்திக்கவும், எல்லாம் சரியாக உள்ளது. இந்த பக்கம் வரை மாறிவருகிறோம்.',
      button: 'மீண்டும் ஏற்றுக் கொள்',
    },
  },
};

export default TA_RESOURCE;
