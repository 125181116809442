import moment from 'moment';
import 'moment-duration-format';

const formatToYear = (date: string) => moment(date).format('YYYY');

const minutesToHours = (runtime: number): string | undefined => {
  const t = moment.duration(runtime, 'minutes').format('h[h] m[min]');
  return `${t}`;
};

const millisecondsToHours = (duration: string): string => {
  const t = moment.duration(duration, 'seconds').format('h[h] m[min]');
  return `${t}`;
};

const numberToAmPm = (h: number | string): string => {
  return moment(`${h}`, 'hh').format('hh:mm a');
};

const hoursAndMinutesToAm = (time: string): string => {
  return moment(`${time}`, 'hh:mm').format('hh:mm a');
};

const toUnixTime = (hour: string | number): string => {
  const h = moment(hour, 'HH:mm:ss').unix();
  return h.toString();
};

export {
  formatToYear,
  millisecondsToHours,
  minutesToHours,
  toUnixTime,
  numberToAmPm,
  hoursAndMinutesToAm,
};
