const PT_RESOURCE = {
  translation: {
    loading: 'A carregar',
    search: {
      cancel: 'Cancelar',
      noResults:
        'Nenhum resultado de procura encontrado, mas os abaixo definitivamente valem a pena conferir.',
    },
    menu: {
      switchProfiles: 'Mudar perfis',
      settings: 'Definições',
      close: 'Fechar',
      logOut: 'Terminar sessão',
    },
    button: {
      apply: 'Aplicar',
      continue: 'Continuar',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      play: 'Reproduzir',
      addToWatchList: 'Adicionar à watchlist',
      removeFromWatchList: 'Remover da watchlist',
      moreInfo: 'Mais informações',
      resume: 'Retomar',
      close: 'Fechar',
    },
    login: {
      header: 'Início de sessão da conta',
      title: 'Bem-vindo ao',
      subTitle:
        'Aproveite ao máximo as suas assinaturas de plataformas de streaming da Disney, Amazon e muito mais.',
      text: 'Tudo o que ver num único lugar.',
      button: 'Iniciar sessão',
      placeholder: {
        username: 'Nome de utilizador',
        password: 'Palavra-passe',
      },
      errors: {
        email: {
          noValid: 'O e-mail tem de ser um endereço de e-mail válido',
          required: 'O E-mail é um campo obrigatório',
        },
        password: {
          required: 'A palavra-passe é um campo obrigatório',
        },
        wrongPass: {
          first: 'Ops, essa não era a palavra-passe correta. Tente novamente ou clique em ',
          second: '“Esqueci-me da palavra-passe” ',
          third: 'botão abaixo.',
        },
      },
    },
    forgotPassword: {
      message:
        'Não se preocupe. Redefinir sua palavra-passe é fácil. Basta digitar o seu endereço de e-mail abaixo e clicar em "ENVIAR"',
      button: 'ENVIAR',
      placeholder: {
        username: 'Nome de utilizador',
      },
      errors: {
        email: {
          required: 'E-mail é um campo obrigatório',
        },
        emailNotRegister: 'Endereço de e-mail não registado!',
        emailSent: 'Reposição de palavra-passe enviada por e-mail!',
      },
    },
    profilesPage: {
      title: 'Escolha o perfil',
      subTitle:
        'Todos no seu carro podem ter uma experiência de TV personalizada. Adicione um novo perfil.',
      newProfile: 'Novo perfil',
    },
    newProfile: {
      isKid: 'Crianças?',
      loading: 'A carregar',
      profileCreated: 'Perfil criado com sucesso!',
      profileSelection: 'Seleção de perfil',
    },
    settings: {
      accountDetails: {
        primary: 'Detalhes da conta',
        secondary: 'Editar nome do titular da conta e e-mail.',
      },
      manageProfiles: {
        primary: 'Gerir perfis',
        secondary: 'Editar detalhes',
      },
      parentControl: {
        primary: 'Controlo parental',
        secondary: 'Criar PIN / Alterar PIN',
        instruction: '(Insira 4 dígitos enquanto o teclado estiver aberto)',
        recoverPin: 'Recuperar PIN',
        pinSentTo: 'PIN enviado para',
      },
      membershipStatus: {
        primary: 'Estado da subscrição',
        secondary: 'Ativo',
      },
      deactivateAccount: {
        primary: 'Desativar conta',
        secondary: 'Feche a sua conta e dissocie os seus dispositivos.',
        enterPass: 'Introduza a sua palavra-passe',
      },
      activeLanguage: 'Linguagem ativa',
      defaultLanguage: 'Linguagem do sistema',
      privacyPolicy: {
        text: 'Política de privacidade',
      },
      settings: 'Definições',
      lang: {
        en: 'Inglesa',
        it: 'Italiana',
        de: 'Alemã',
        esar: 'Espanhola(Argentina)',
        fr: 'Francesa',
        ja: 'Japonesa',
        ro: 'Romena',
        da: 'Dinamarquesa',
        nl: 'Holandesa',
        ca: 'Catalã',
        lb: 'Luxemburguesa',
        no: 'Norueguesa',
        ptbr: 'Portuguesa(Brasil)',
        zhcn: 'Chinesa',
        ms: 'Malaia',
        ta: 'Tâmil',
        bs: 'Bósnia',
        bg: 'Búlgara',
        hr: 'Croata',
        tr: 'Turca',
        cs: 'Tcheca',
        et: 'Estoniana',
        fi: 'Finlandesa',
        el: 'Grega',
        hu: 'Húngara',
        ga: 'Irlandesa',
        is: 'Islandesa',
        lv: 'Letã',
        lt: 'Lituana',
        mt: 'Maltesa',
        pl: 'Polonesa',
        pt: 'Portuguesa(Portugal)',
        ru: 'Russa',
        sr: 'Sérvia',
        sk: 'Eslovaca',
        sl: 'Eslovena',
        sv: 'Sueca',
        mi: 'Maori',
        ar: 'Árabe',
        af: 'Africâner',
        zhtw: 'Chinesa(Tradicional)',
        es: 'Espanhola(Espanha)',
        ko: 'Coreana',
        enus: 'Inglês (Estados Unidos)',
        deat: 'Alemão (Áustria)',
        dech: 'Alemão (Suíça)',
        deli: 'Alemão (Liechtenstein)',
        dede: 'Alemão (Alemanha)',
        enca: 'Inglês (Canadá)',
        engb: 'Inglês',
        enie: 'Inglês (Irlanda)',
        ennz: 'Inglês (Nova Zelândia)',
        enza: 'Inglês (África do Sul)',
        esmx: 'Espanhol (México)',
        frca: 'Francês (Canadá)',
        frch: 'Francês (Suíça)',
        itch: 'Italiano (Suíça)',
        jajp: 'Japonês (Japão)',
        kokr: 'Coreano (Coreia do Sul)',
        nb: 'Norueguês (Bokmål)',
        nn: 'Norueguês (Nynorsk)',
        zhch: 'Chinês (RPC)',
      },
      language: {
        primary: 'Idioma',
      },
    },
    show: {
      more: ' Mostrar mais',
      less: ' Mostrar menos',
    },
    showPage: {
      seasons: 'Temporadas',
      cast: 'Elenco',
      producers: 'Produtores',
      genre: 'Género',
      directedBy: 'Realizado por',
      season: 'Temporada',
    },
    accountDetails: {
      firstName: 'Nome',
      lastName: 'Apelido',
      email: 'Email',
      password: 'Palavra-passe',
      changePassword: 'Alterar palavra-passe',
      forgotPassword: 'Esqueci-me da minha palavra-passe',
    },
    childPinPopup: {
      title: 'Proteção de crianças',
      subTitle: 'Criar (atualizar) PIN para Proteção de Crianças',
      pinUpdated: 'PIN atualizado com sucesso',
      pinIsIncorrect: 'PIN incorreto',
    },
    deactivateAccount: {
      title: 'Tem a certeza de que quer desativar a sua conta?',
      description:
        'Assim que o fizer, a sua sessão será terminada para todos os serviços e não poderá mais visualizar o conteúdo no seu carro.',
      deactivateButton: 'Desativar conta',
    },
    servicesPage: {
      add: 'Adicionar aos serviços',
      remove: 'Retirar dos serviços',
      overflow: {
        title: 'Pacotes selecionados',
        subtitle: 'Selecione os seus serviços',
        description: 'Adicione cada serviço que usa ao seu guia.',
      },
    },
    manageProfile: {
      text: 'Gerir perfis',
    },
    watchlist: {
      title: 'Aqui encontram-se todos os filmes e programas que adicionou à sua watchlist',
      noTitles: 'Atualmente não tem títulos na sua watchlist',
      addText:
        'Para adicionar, clique em + Adicionar à watchlist em qualquer programa que gostaria de voltar e assistir numa data posterior.',
      subtext: {
        start: 'Para adicionar, clique em ',
        toWatchList: 'Adicionar à lista de vigilância',
        end: 'ou qualquer espetáculo a que gostasses de voltar e ver mais tarde.',
      },
    },
    streamingService: {
      title: 'Escolha Serviços de Streaming',
      subTitle: 'Adicione seus serviços selecionados para aparecer no seu Guia de TV',
      streamingServices: 'Serviços de Streaming',
    },
    profileUpdate: {
      backToSettings: 'Voltar para Definições',
      updatedSuccess: 'O utilizador foi atualizado com sucesso',
      apply: 'Aplicar',
      wrongPassword: 'Palavra-passe incorreta',
    },
    changePasswordPage: {
      backToSettings: 'Voltar para definições',
      currentPass: 'Palavra-passe atual',
      newPass: 'Nova Palavra-passe',
      confirmPass: 'Confirmar palavra-passe',
      change: 'Alterar',
      changeUserInfo: 'Alterar Informações do utilizador',
      passwordDoNotMatch: 'As palavra-passe não são iguais ou inválidas',
      passwordUpdated: 'Palavra-passe atualizada com sucesso',
    },
    noServices: {
      title: 'Atualmente não tem serviços no seu Guia de TV',
      text: 'Para aproveitar ao máximo o ScreenHits TV, necessita de adicionar serviços. Clique aqui para adicionar os streamers nos quais já se inscreveu sem nenhum custo extra. E se ainda não é assinante de nenhum serviço de streaming, adicione nosso pacote GRATUITO e comece a descobrir ótimos conteúdos da BBC, ITV, My5, Channel 4, UKTV e STV.',
      button: 'Adicionar serviços',
    },
    deleteProfile: {
      cantDeleteProfile: 'Não pode eliminar o seu perfil atual',
      areYouSure: 'Tem certeza de que deseja eliminar este perfil?',
      delete: 'Eliminar',
    },
    notFoundPage: {
      title: 'Ocorreu um erro',
      text: 'Não se preocupe, porém, está tudo bem. Voltaremos com esta página.',
      home: 'Página inicial',
    },
    noInternet: {
      title: 'Sem Conexão à Internet',
      description: 'Não se preocupe, tudo está bem. Vamos voltar com esta página.',
      button: 'Recarregar',
    },
  },
};

export default PT_RESOURCE;
