const JA_RESOURCE = {
  translation: {
    loading: '読み込み中',
    search: {
      cancel: 'キャンセル',
      noResults:
        '検索結果は見つかりませんでしたが、以下のものは間違いなくチェックする価値があります.',
    },
    menu: {
      switchProfiles: 'プロフィール変更',
      settings: '設定',
      close: '閉じる',
      logOut: 'ログアウト',
    },
    button: {
      apply: '適用',
      continue: '継続',
      cancel: 'キャンセル',
      confirm: '確認',
      play: 'プレイ',
      addToWatchList: 'ウォッチリストに追加',
      removeFromWatchList: 'ウォッチリストから削除',
      moreInfo: '詳細はこちら',
      resume: '再開',
      close: '閉じる',
    },
    login: {
      header: 'アカウントログイン',
      title: 'ようこそ',
      subTitle: 'Netflix、Disney、Amazonなどのストリーミング配信を最大限に活用できます。',
      text: '観たいものをすべて一度に。',
      button: 'ログイン',
      placeholder: {
        username: 'ユーザー名',
        password: 'パスワード',
      },
      errors: {
        email: {
          noValid: '電子メールは有効な電子メールである必要があります',
          required: '電子メールは必須項目です',
        },
        password: {
          required: 'パスワードは必須項目です',
        },
        wrongPass: {
          first: 'おっと、パスワードが違っていました。再度お試しいただくか ',
          second: '"以下の"パスワードをお忘れの方" ',
          third: 'ボタンをクリックしてください。',
        },
      },
    },
    forgotPassword: {
      message:
        'ご安心ください。パスワードの再設定は簡単です。下記にメールアドレスを入力し、"送信 "をクリックするだけです。',
      button: '送信',
      placeholder: {
        username: 'ユーザー名',
      },
      errors: {
        email: {
          required: '電子メールは必須項目です',
        },
        emailNotRegister: 'メールアドレスが登録されていません',
        emailSent: 'パスワードリセットメールを送信しました',
      },
    },
    profilesPage: {
      title: 'プロフィールを選択',
      subTitle:
        '車内の皆さんは個別のテレビ体験を楽しめます。新しいプロフィールを追加してください。',
      newProfile: '新着プロフィール',
    },
    newProfile: {
      isKid: 'お子様は？',
      loading: '読み込み中',
      profileCreated: 'プロフィールの作成に成功しました',
      profileSelection: 'プロフィールの選択',
    },
    settings: {
      accountDetails: {
        primary: 'アカウント詳細',
        secondary: 'アカウントホルダー名とメールアドレスを編集します。',
      },
      manageProfiles: {
        primary: 'プロフィールの管理',
        secondary: '詳細を編集',
      },
      parentControl: {
        primary: 'ペアレンタルコントロール',
        secondary: '暗証番号の作成／暗証番号の変更',
        instruction: '(キーボードを開いた状態で 4 桁の数字を入力してください)',
        recoverPin: 'PIN の回復',
        pinSentTo: 'PIN の送信先',
      },
      membershipStatus: {
        primary: '会員状況',
        secondary: '有効',
      },
      deactivateAccount: {
        primary: 'アカウントの無効化',
        secondary: 'アカウントを閉じ、デバイスのリンクを解除してください。',
        enterPass: 'パスワードを入力してください',
      },
      activeLanguage: '有効言語',
      defaultLanguage: 'システム言語',
      privacyPolicy: {
        text: 'プライバシーポリシー',
      },
      settings: '設定',
      lang: {
        en: '英語',
        it: 'イタリア語',
        de: 'ドイツ語',
        esar: 'スペイン語(アルゼンチン)',
        fr: 'フランス語',
        ja: '日本語',
        ro: 'ルーマニア語',
        da: 'デンマーク語',
        nl: 'オランダ語',
        ca: 'カタロニア語',
        lb: 'ルクセンブルク語',
        no: 'ノルウェー語',
        ptbr: 'ポルトガル語(ブラジル)',
        zhcn: '中国語',
        ms: 'マレーシア語',
        ta: 'タミル語',
        bs: 'ボスニア語',
        bg: 'ブルガリア語',
        hr: 'クロアチア語',
        tr: 'トルコ語',
        cs: 'チェコ語',
        et: 'エストニア語',
        fi: 'フィンランド語',
        el: 'ギリシア語',
        hu: 'ハンガリー語',
        ga: 'アイルランド語',
        is: 'アイスランド語',
        lv: 'ラトビア語',
        lt: 'リトアニア語',
        mt: 'マルタ語',
        pl: 'ポーランド語',
        pt: 'ポルトガル語（ポルトガル）',
        ru: 'ロシア語',
        sr: 'セルビア語',
        sk: 'スロバキア語',
        sl: 'スロベニア語',
        sv: 'スウェーデン語',
        mi: 'マオリ語',
        ar: 'アラビア語',
        af: 'アフリカーンス語',
        zhtw: '中国語(繁体字)',
        es: 'スペイン語（スペイン）',
        ko: '韓国語',
        enus: '英語 (アメリカ合衆国)',
        deat: 'ドイツ語 (オーストリア)',
        dech: 'ドイツ語 (スイス)',
        deli: 'ドイツ語 (リヒテンシュタイン)',
        dede: 'ドイツ語 (ドイツ)',
        enca: '英語 (カナダ)',
        engb: '英語',
        enie: '英語 (アイルランド)',
        ennz: '英語 (ニュージーランド)',
        enza: '英語 (南アフリカ)',
        esmx: 'スペイン語 (メキシコ)',
        frca: 'フランス語 (カナダ)',
        frch: 'フランス語 (スイス)',
        itch: 'イタリア語 (スイス)',
        jajp: '日本語 (日本)',
        kokr: '韓国語 (韓国)',
        nb: 'ノルウェー語 (ブークモール)',
        nn: 'ノルウェー語 (ニーノシュク)',
        zhch: '中国語 (中華人民共和国)',
      },
      language: {
        primary: '言語',
      },
    },
    show: {
      more: ' もっと見る',
      less: ' 少なく見る',
    },
    showPage: {
      seasons: '季節',
      cast: 'キャスト',
      producers: 'プロデューサー',
      genre: 'ジャンル',
      directedBy: '監督',
      season: 'シーズン',
    },
    accountDetails: {
      firstName: '名（ファーストネーム）',
      lastName: '姓（ラストネーム）',
      email: '電子メール',
      password: 'パスワード',
      changePassword: 'パスワードの変更',
      forgotPassword: 'パスワードを忘れた方',
    },
    childPinPopup: {
      title: 'チャイルド・プロテクション',
      subTitle: 'チャイルド・プロテクションのPIN を作成（更新）する',
      pinUpdated: 'PIN のアップデートに成功しました',
      pinIsIncorrect: 'PIN が正しくありません',
    },
    deactivateAccount: {
      title: '本当にアカウントを停止しますか？',
      description:
        '停止すると、すべてのサービスからログアウトされ、車内でコンテンツを閲覧することができなくなります',
      deactivateButton: 'アカウントの無効化',
    },
    servicesPage: {
      add: 'サービスに追加',
      remove: 'サービスから削除',
      overflow: {
        title: '選択されたパック',
        subtitle: 'サービスを選択してください',
        description: '利用する各サービスをガイドに追加してください。',
      },
    },
    manageProfile: {
      text: 'プロフィールの管理',
    },
    watchlist: {
      title: 'ウォッチリストに追加されたすべての映画と番組が表示されます。',
      noTitles: '現在、ウォッチリストに登録されているタイトルはありません。',
      addText:
        '追加するには、後で戻って視聴したい番組の「＋ウォッチリストに追加」をクリックしてください。',
      subtext: {
        start: '追加するには、以下をクリックしてください。',
        toWatchList: 'ウォッチリストに追加する',
        end: '後日戻って観たい番組がある場合。',
      },
    },
    streamingService: {
      title: 'ストリーミングサービスを選ぶ',
      subTitle: '選択したサービスをテレビガイドに追加して表示する',
      streamingServices: 'ストリーミング サービス',
    },
    profileUpdate: {
      backToSettings: '設定に戻る',
      updatedSuccess: 'ユーザーの更新に成功しました',
      apply: '適用',
      wrongPassword: 'パスワードが違います',
    },
    changePasswordPage: {
      backToSettings: '設定に戻る',
      currentPass: '現在のパスワード',
      newPass: '新しいパスワード',
      confirmPass: 'パスワードの確認',
      change: '変更',
      changeUserInfo: 'ユーザー情報の変更',
      passwordDoNotMatch: 'パスワードが一致しない、または無効です',
      passwordUpdated: 'パスワードの更新に成功しました',
    },
    noServices: {
      title: '現在、TV ガイドにはサービスがありません',
      text: 'ScreenHits TV を最大限に活用するには、サービスを追加する必要があります。 ここをクリックして、すでに購入しているストリーマーを追加料金なしで追加してください。 また、現在どのストリーミング・サービスも購入していない場合は、無料のバンドルをここに追加して、すばらしいコンテンツを見つけてください。',
      button: 'サービスを追加',
    },
    deleteProfile: {
      cantDeleteProfile: '現在のプロフィールは削除できません',
      areYouSure: '本当にこのプロフィールを削除してもよろしいですか?',
      delete: '削除',
    },
    notFoundPage: {
      title: 'エラーが発生しました',
      text: 'でも心配しないでください、すべて大丈夫です。 このページに戻ってきます。',
      home: 'ホームページ',
    },
    noInternet: {
      title: 'インターネットに接続されていません',
      description: '心配しないでください、すべて大丈夫です。このページで戻ります。',
      button: 'リロード',
    },
  },
};

export default JA_RESOURCE;
