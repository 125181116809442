import {
  getLocationFromLocalStorage,
  getSearchQFromLocalStorage,
  getSelectedProfileFromLocalStorage,
  getUserDataFromLocalStorage,
} from 'helpers/localStorage';
import { createContext, useContext, useReducer } from 'react';
import { OtherGenreFilterEnum } from 'types/OtherGenre';
import { initSelectedMedia, initSelectedProfile, initUserData } from './init';
import reducer from './reducer';
import { AppStateContext, IAppContext } from './types';

const initialState: AppStateContext = {
  userData: JSON.parse(getUserDataFromLocalStorage() as unknown as string) || initUserData,
  isAuth: false,
  childProtection: false,
  watchList: [],
  genreParams: {
    type: 'films',
    genre_id: 1,
  },
  genreMedia: [],
  selectedMedia: initSelectedMedia,
  selectedProfile:
    JSON.parse(getSelectedProfileFromLocalStorage() as unknown as string) || initSelectedProfile,
  redirectURL: '',
  backgrounds: [],
  redirectedFrom: undefined,
  prevUrl: '',
  otherGenreFilter: OtherGenreFilterEnum.FILMS,
  selectedGenreId: 1,
  location: getLocationFromLocalStorage() || 'JP',
  searchQuery: getSearchQFromLocalStorage() || '',
  channelId: undefined,
};

export const AppContext = createContext<IAppContext>({ state: initialState, dispatch: () => null });

export const useAppContext = (): IAppContext => useContext(AppContext);

interface Props {
  children: React.ReactNode;
}

export const AppStoreProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = { state, dispatch };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
