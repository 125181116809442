import axios, { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { getTokenFromCookies, removeTokenFromCookies } from 'helpers/cookies';
import { appLinks } from 'routes/routes';

const webSiteUrl = window.location.origin;

const TEST_DEVICE_TYPE = 'oem1';
const STAGING_DEVICE_TYPE = 'oem1-staging';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getTokenFromCookies();
  if (token) {
    setAuthorizationHeader(token);
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const status = error.response?.status;

  if (401 == status && window.location.pathname != appLinks.loginWithQR.link) {
    localStorage.clear();
    removeTokenFromCookies();
    location.replace(appLinks.loginWithQR.link);
  }

  return Promise.reject(error);
};

function setupInterceptorsTo(instance: AxiosInstance): AxiosInstance {
  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);
  return instance;
}

const apiOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'Device-Type':
      webSiteUrl !== process.env.REACT_APP_PRODUCTION_WEB_SIZE_URL
        ? STAGING_DEVICE_TYPE
        : TEST_DEVICE_TYPE,
  },
};

const publicApiClient: AxiosInstance = axios.create(apiOptions);
const apiClient: AxiosInstance = setupInterceptorsTo(axios.create(apiOptions));

const setAuthorizationHeader = (token: string): void => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const setApiLanguageHeader = (language: string): void => {
  apiClient.defaults.headers.common['language'] = language;
  apiClient.defaults.headers.common['language'] = language;
};

export { apiClient, publicApiClient, setAuthorizationHeader, setApiLanguageHeader };
