import { setApiLanguageHeader, setAuthorizationHeader } from 'api/apiClient';
import BackdropLoader from 'components/Loaders/BackdropLoader';
import { getTokenFromCookies, getUserTokenFromCookies } from 'helpers/cookies';
import { getLocationFromLocalStorage } from 'helpers/localStorage';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../store';
import { setIsAuth, SetLocation } from '../store/actions';
import { getBrowserLanguage } from 'helpers/Common';

interface Props {
  children: React.ReactNode;
}

const UserDataWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const { dispatch } = useAppContext();
  const location = getLocationFromLocalStorage();
  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const getCurrentUser = useCallback(async () => {
    const accessToken = getTokenFromCookies();
    const userToken = getUserTokenFromCookies();

    const userLanguage = getBrowserLanguage();

    setApiLanguageHeader(location || userLanguage || 'en');

    if (location) {
      dispatch(SetLocation(location));
      i18n.changeLanguage(location.replace('-', ''));
    } else {
      dispatch(SetLocation(userLanguage));
      i18n.changeLanguage(userLanguage.replace('-', ''));
    }

    if (userToken && !accessToken) {
      setAuthorizationHeader(userToken);
    }

    if (accessToken) {
      setAuthorizationHeader(accessToken);
      dispatch(setIsAuth(true));
      // await getCurrentLocation();
    }
  }, [dispatch, i18n, location]);

  useLayoutEffect(() => {
    getCurrentUser().then(() => setIsLoading(false));
  }, [getCurrentUser]);

  return isLoading ? <BackdropLoader /> : <div>{children}</div>;
};

export default UserDataWrapper;
