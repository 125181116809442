import NoInternetPlaceholder from 'components/ErrorPlaceholders/NoInternetPlaceholder';
import SimpleErrorPlaceholder from 'components/ErrorPlaceholders/SimpleErrorPlaceholder';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  hasError: boolean;
  setHasError(v: boolean): void;
}

interface State {
  hasError: boolean;
  isOnline: boolean;
}

const state: State = {
  hasError: false,
  isOnline: true,
};

interface FnProps {
  children: React.ReactNode;
}

const ErrorBoundaryFn: React.FC<FnProps> = ({ children }: FnProps) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
    // eslint-disable-next-line
  }, [location.key]);

  return (
    <ErrorBoundary hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundary>
  );
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = state;
  }

  componentDidMount(): void {
    if (!navigator.onLine) {
      this.setState({
        ...this.state,
        isOnline: false,
      });
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(): void {
    this.props.setHasError(true);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.state.isOnline) {
        return <SimpleErrorPlaceholder />;
      }
      return <NoInternetPlaceholder />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryFn;
